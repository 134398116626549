
import React from "react";
import { Link, NavLink } from "react-router-dom";
export const columns = [
  
    
    {
      name: "Sr.No.",
     selector: "id",
      sortable: true,
      width:"75px"
      
  },
  {
    name: "Campaign Name",
   selector: "name",
   className:"texttransformcapitalize",

    sortable: true,
     cell: (d) => (
         <Link to={`/ViewCampaign/${d.id}`}  className="dlink">
            {d.name}
          </Link>
        )
    
},
{
    name: "Created date",
   selector: "createdDate",
    sortable: true,
    className:"texttransformcapitalize"

   
    
},
{
    name: "Start date",
   selector: "startDate",
    sortable: true,
    className:"texttransformcapitalize"

    
},
{
    name: "End date",
   selector: "endDate",
    sortable: true,
    className:"texttransformcapitalize"

    
},
{
    name: "HC name",
   selector: "calculatorName",
    sortable: true,
    className:"texttransformcapitalize"

}
,{
    name: "Coupon code",
   selector: "couponName",
   className:"texttransformcapitalize",

    sortable: true,
     cell: (d) => (
           d.couponName== null ? <Link to={`/AddCoupon/${d.id}`} className="dlink couponlink">Add Coupon</Link>  : 
            <Link to={`/ViewCoupon/${d.id}`}  className="dlink ">
            {d.couponName}
          </Link>


        )
    
},
{
  name: "Total visitors",
 selector: "visitors",
  sortable: true,
  width:"120px"

},
    
{
    name: "status",
   selector: "status",
    sortable: true,
    className:"texttransformcapitalize"

    
  },
      
  
    
  ];
  export const data = [
    {
      id:1,
      campaignname:"Ashvini",
      createddt:"5678675456",
      startdt:"01 Aug 2022",
      enddt:"08 Aug 2022",
      hcname:"PCOS",
      couponcode:"TATC-012345",
      totalvisitor:"20",
      status:"Completed",

    },
     
  
  ]
  