import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data2";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {ImDownload2} from 'react-icons/im'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {FiFilter} from 'react-icons/fi';
import Footer from "../Footer";
import { useHistory } from "react-router-dom";



 
const Calculator=()=>{

  const [session ,setSession] = useState(false);
  let history = useHistory();

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);

  const [ fileData, setFileData ] = useState();

  const fileHeaders = [
    { label: "Sr.No. ", key: "id" },
    { label: "HC Name", key: "calculatorName" },
    { label: "DOA", key: "date" },
    { label: "Total Capaigns", key: "totalCampaigns" },
    { label: "Total Visitors", key: "totalVisitors" },
    { label: "Total Conversions", key: "totalConversions" },
    { label: "Ongoging Campaigns", key: "totalCampaigns" },

];

    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     const [data, setData] = useState([]);
    const tableData = {
      columns,
      data,
      
    };

    useEffect(() => {
      loadUsers();
    }, []);
  
    const loadUsers = async () => {
      const result = await axios.get("/calculator/getAllCalculator");

      setData(result.data.data);
      setFileData(result.data.data);

    };

    const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
     setIsOpen(!isOpen);
  }

  return (
<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar"> Calculators</span>
          
         </div><br/><br/><br/>
         <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Calculators/ Health Calculators</span></div>


         <div className={isExpanded ? "usergrid" : "usergrid1"}>  
            <div className="clientbtn row">
            

              <div className="col-11">
         <span className="adclptag">Health Calculators</span>
         </div>
         <Tippy content="download">
        <div className="col-1">

         {fileData?.length &&
      <CSVLink
        headers={fileHeaders}
        data={fileData}
        filename="CalculatorList.csv"
        target="_blank"
      > 
      <ImDownload2 size="1.5em" className="iodownload"/>     
         
     </CSVLink> 
     } 
             </div>
 </Tippy>
        

         <div className="main">
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={false} //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
          

        />
      </DataTableExtensions>
      <div className='row margintop2'></div>
      <div className='row margintop2'></div>
    </div>
    </div>
</div>
<div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div>      
         </div>
           );
}
 
export default Calculator;