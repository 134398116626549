import React, { useState, useEffect } from "react";


const Footer = () => {

    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
    const handleToggler =()=>{
      if(isExpanded){
        setIsExpanded(false);
        localStorage.setItem('sidebar-collapsed',true);
       return;
     }
     setIsExpanded(true);
      localStorage.removeItem('sidebar-collapsed');
   }
    return (
        //  <div className={isExpanded ? "footerexp" : "footercpls"}> </div>
        <div className="">  
        <p className="">Copyright © 2023 by Deva Consultancy Services Pvt. Ltd. All Rights Reserved.</p>
  </div>
    )
    };

export default Footer;