import Sidebar from '../Sidebar';
import React, { useState, useEffect ,useRef} from "react";
import {RiMenuLine} from 'react-icons/ri'; 
import { columns ,data, heading, Rows } from "./Data1";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {FiPrinter} from 'react-icons/fi'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useHistory } from "react-router-dom";
import Footer from "../Footer";
import { useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';




const ViewPatientProfile =()=>{
debugger;
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };
   
   useEffect(() => {
    gesessiondata();
  }, [session]);

  //for print
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle:'Patient-data',
   // onAfterPrint:() => toast.success("printed Successfully!")
    

  });
  var  medicalhistory1 = false;
  var  medicalhistory2 = false;
  var  medicalhistory3 = false;
  var  medicalhistory4 = false;
  var  medicalhistory5 = false;
  var  medicalhistory6 = false;
  var  medicalhistory7 = false;

  

  var  personalHistory = false;
 var  familyHistory = false;
  var cronicity =false;
  var cMedication =false;
 var autoImmunity= false;
 var metabolicDisterbances= false;
 var pscycologicalDisterbance= false;
 const [patientdetail, setPatientdetail] = useState({
  calculatorName:"",
name: "",
campaignId:"",
gender:"",
contactNumber: "",
campaignName: "",

emailId: "",
height: "",
weight: "",
occupation: "",
country: "",
state: "",
city: "",
generalAnswers:[],
calculatorAnswers:[]
  })

  const {id} = useParams();
  var patientDataInfo;
  useEffect(()=>{
    loadPatientdetail()
  },[])
  console.log("local Storage  = ",localStorage.getItem('patientData'));
  const patientData = JSON.parse(localStorage.getItem('patientData'));
  debugger;
  if (patientData != null && patientData != "") {
    debugger;
    patientDataInfo = patientData.filter((value) => value.id == id
    );
   // setPatientdetail(patientDataInfo);
  }

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();


     const redirect =()=>{
      history.push("/PatientList");
     }
     

     

      

  const loadPatientdetail = async () =>{
      debugger;
      const res = axios.get(`/patient/getAllPatientsById?id=${id}`)

      res.then((result) => {
        debugger;
        setPatientdetail(result.data.data);
      console.log("patdetail",result.data.data);

    
    
    })
     
      
  }
  
  if(patientDataInfo !=null){
    debugger;
    //for medical history question1
    const questid1bp= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='bp'
    );
    
    if(questid1bp != null && questid1bp.length>0){
      medicalhistory1= true;
    }
    const questid1diabetes= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='diabetes'
    );
    debugger;
    if(questid1diabetes != null && questid1diabetes.length>0){
      medicalhistory2= true;
    }
    const questid1hd= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='hd'
    );
    
    if(questid1hd != null && questid1hd.length>0){
      medicalhistory3= true;
    }
    const questid1cholestrol= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='cholestrol'
    );
    
    if(questid1cholestrol != null && questid1cholestrol.length>0){
      medicalhistory4= true;
    }
    const questid1arthritis= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='arthritis'
    );
    
    if(questid1arthritis != null && questid1arthritis.length>0){
      medicalhistory5= true;
    }
    const questid1thyroid= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='thyroid'
    );
    
    if(questid1thyroid != null && questid1thyroid.length>0){
      medicalhistory6= true;
    }
    const questid1pcos= patientDataInfo[0].generalAnswers.filter((value) =>  value.questionId == 1 && 
    value.answer=='pcos'
    );
    
    if(questid1pcos != null && questid1pcos.length>0){
      medicalhistory7= true;
    }
  //for summry section question 2
    const questid2= patientdetail.generalAnswers.filter((value) =>  value.questionId == 2 && 
    value.answer=='yes');
    
    if(questid2 != null && questid2.length>0){
        personalHistory= true;
    }
    
    const questid5= patientdetail.generalAnswers.filter((value) =>  value.questionId == 5 && 
    value.answer=='yes');
    
    if(questid5 != null && questid5.length>0){
      familyHistory= true;
    }
    const questid4= patientdetail.generalAnswers.filter((value) =>  value.questionId == 4 && 
    value.answer=='yes');
    
    if(questid4 != null && questid5.length>0){
      cronicity= true;
    }
    const questid3= patientdetail.generalAnswers.filter((value) =>  value.questionId == 3 && 
    value.answer=='yes');
    
    if(questid3 != null && questid3.length>0){
      cMedication= true;
    }
    const questid6= patientdetail.generalAnswers.filter((value) =>  value.questionId == 6 && 
    value.answer=='yes');
    
    if(questid6 != null && questid6.length>0){
      autoImmunity= true;
    }
     const questid17= patientdetail.calculatorAnswers.filter((value) =>  value.questionId == 1 && value.answer=='excessweightgainloss' 
     || value.questionId == 1 && value.answer=='moderateweightgainloss'
     || value.questionId == 2 && value.answer=='wholebody'
     ||value.questionId == 2 && value.answer=='extermityonly'
     || value.questionId == 3 && value.answer=='oftenneedmedic'
     ||value.questionId == 3 && value.answer=='oftennoneedmedic'
     || value.questionId == 4 && value.answer=='oftenandneedmedication'
     ||value.questionId == 4 && value.answer=='oftenandneednomedication'
     || value.questionId == 5 && value.answer=='yesandpain'
     ||value.questionId == 5 && value.answer=='yeswithnopain'
     || value.questionId == 6 && value.answer=='recurrent'
     ||value.questionId == 6 && value.answer=='seasonal'
     || value.questionId == 7 && value.answer=='insomnia'
     ||value.questionId == 7 && value.answer=='distributed');
    
     if(questid17 != null && questid17.length>0){
       metabolicDisterbances= true;
     }
     const questid8= patientdetail.calculatorAnswers.filter((value) =>  value.questionId == 8 && value.answer=='irritation' 
     || value.questionId == 8 && value.answer=='anxiety'
     || value.questionId == 8 && value.answer=='depression'
     ||value.questionId == 8 && value.answer=='dullness'
     || value.questionId == 8 && value.answer=='moodswings'
     ||value.questionId == 8 && value.answer=='cry'
     || value.questionId == 8 && value.answer=='foegtfulness'
     ||value.questionId == 8 && value.answer=='stress'
     );
    
     if(questid8 != null && questid8.length>0){
       pscycologicalDisterbance= true;
     }
  
    console.log('qid1',questid2)
  
   }
  //for conclusion
  
  


 


    return (
      <div className={ isExpanded ? " container" : "clpsMenu"}> 
      {/* <div className="container ">   */}
     <Sidebar isExpanded={isExpanded}></Sidebar>
     <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
     <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
     <span className="titlebar">Patient List</span>
      
     </div><br/><br/><br/>
     <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Patient List/Patient Profile</span></div>


     <div  className={isExpanded ? "usergrid" : "usergrid1"}>  
        <div className="clientbtn row">
        <div className="col-1">
        </div>
        

          <div className="col-10">
     <span className="adclptag">Patient List</span>
     </div>
     <Tippy content="Print">
    <div className="col-1">
 
  <FiPrinter size="1.4em" className="iodownload" onClick={handlePrint}/>     
     
 
         </div>
</Tippy>
    
</div>
<div ref={componentRef}>

<div className='row margintop'>
  <div className='col-4'>
    <div className='patdetaildiv'>
      <div><span className='patnme'style={{textTransform:"capitalize"}}>{patientdetail.name}</span></div>
      <div className='patno'><span className=''>{patientdetail.contactNumber}</span></div>
      <span className='pateml'>{patientdetail.emailId}</span>
<br/><br/><br/>
<div className='row margintop1'>
  <div className='col-4'>
    <span className='hcspn'>Gender -</span>
  </div>
  <div className='col-1'></div>

  <div className='col-6 pcospn' style={{textTransform:"capitalize"}}>{patientdetail.gender}</div>
</div>
<div className='row margintop1'>
  <div className='col-4'>
    <span className='hcspn'>HC -</span>
  </div>
  <div className='col-1'></div>

  <div className='col-6 pcospn'style={{textTransform:"capitalize"}}>{patientdetail.calculatorName}</div>
</div>
<div className='row margintop1'>
  <div className='col-4'>
    <span className='hcspn'>Campaign -</span>
  </div>
  <div className='col-1'></div>
  <div className='col-6 pcospn'>{patientdetail.campaignName}</div>
</div>
<div className='row margintop1'>
  <div className='col-4'>
    <span className='hcspn'>Date -</span>
  </div>
  <div className='col-1'></div>

  <div className='col-6 pcospn'>{patientdetail.date}</div>
</div>
<div className='row margintop1'>
  <div className='col-4'>
    <span className='hcspn'>BMI -</span>
  </div>
  <div className='col-1'></div>

  <div className='col-6 pcospn'>{patientdetail.bmi}</div>
</div>

    </div>

    <div className='patmedicl'>
      <div className='center1'><span className='questspn '>Medical History</span></div>
      <div className='margintop3'>I am suffering from       </div>
      <div className={medicalhistory6?" margintop3":"hide  margintop3"}><span >Thyroid</span></div> 
      <div className={medicalhistory7?" margintop3":"hide  margintop3"}><span >PCOS</span></div> 
      <div className={medicalhistory1?" margintop3":"hide  margintop3"}><span >BP</span></div> 
      <div className={medicalhistory2?" margintop3":"hide  margintop3"}><span >Diabetes</span></div> 
      <div className={medicalhistory3?" margintop3":"hide  margintop3"}><span >Heart Diaseases</span></div> 

      <div className={medicalhistory4?" margintop3":"hide  margintop3"}><span >Cholestrol</span></div> 
      <div className={medicalhistory5?" margintop3":"hide  margintop3"}><span >arthritis</span></div> 



      {/* <div className='margintop3'>Yes i have done operation before.</div>
      <div className='margintop3'>Lorem Ipsum is simply dummy text of the printing typesetting industry.</div> */}

    </div>
  </div>
  <div className='col-8'>
    <div className='linkbtn'>
      <button className='questionary'>Questionnaire</button>
      <a href="#contactUs"> <span className='conclusion'>Summary</span> </a>
      {/* <button className='conclusion'>Conclusion</button> */}
  </div>
    <div className='quetionridiv'>
      <br/>
      <span className='questspn'>Questionnaire</span>
      <br/><br/><br/>
       {
        patientdetail.calculatorAnswers.map((data1,index)=>(
          <div className='row margintop2'>

<div className='col-1 textright'>{data1.questionId}</div>
        <div className='col-6 textleft'>{data1.question} </div>
        <div className='col-5 textleft flexwrap'>{data1.answer.replace(/,/g, ', ')}</div>
        </div> 

        ))}
        
      
    </div>

    <div className='center quetionridiv'>
      
       <span className='questspn'>Summary</span> 
      <br/><br/>
 <div className='conclutext' id = "contactUs">
  <div className='row'>
    <div className='col-1'></div>
    <div className='col-2 textright'> <span className='hcspn'>BMI:</span>
</div>
    <div className='col-1'><span className='pcospn'>{patientdetail.bmi}</span></div>
    <div className='col-2 textright'><span className='hcspn'>Score:</span></div>
    <div className='col-1'><span className='pcospn'>{patientdetail.score}</span></div>

  </div>
  
  <div className='row margintop2'>
    <div className='col-2'></div>
  <div className='col-3 '><span className='hcspn '>Health Risk Factors:</span></div> 

  </div>
  <div className={personalHistory?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-3' > 
   <span className="pcospn margleft2">Personal History</span>
  </div> 

  </div>
  <div className={familyHistory?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-3' > 
   <span className="pcospn margleft2">Family History</span>
  </div> 

  </div>
  <div className={cronicity?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-3' > 
   <span className="pcospn margleft2">Chronicity </span>
  </div> 

  </div>
  <div className={cMedication?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-3' > 
   <span className="pcospn margleft2"> Current medication</span>
  </div> 

  </div>
  <div className={autoImmunity?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-3' > 
   <span className="pcospn margleft2"> Autoimmunity</span>
  </div> 

  </div>
  <div className={metabolicDisterbances?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-4' > 
   <span className="pcospn margleft2"> Metabolic disturbances</span>
  </div> 

  </div>
  <div className={pscycologicalDisterbance?"row margintop2":"hide row margintop2"}>
    <div className='col-2'></div>
    <div className='col-4' > 
   <span className="pcospn margleft2">Psychological disturbances</span>
  </div> 

  </div>
  
  
  <div className='row margintop2'>
  <div className='col-2'></div>

  <div className="col-3"> 
  {
                    patientdetail.bmiZone==='Red' && (
                      <div className="col-2">

                      <div className="margtop1">
                      <span className="helthrisk-spn">
                       Overweight	
                      </span>
                      </div>
                      </div>
                      )

                }  
                {
                    patientdetail.bmiZone==='Orange' && (
                      <div className="col-2">

                      <div className="margtop1">
                      <span className="helthrisk-spn">
                       Overweight	
                      </span>
                      </div>
                      </div>
                      )

                }  
                {
                    patientdetail.bmiZone==='Yellow' && (
                      <div className="col-2">

                      <div className="margtop1">
                      <span className="helthrisk-spn">
                       underweight	
                      </span>
                      </div>
                      </div>
                      )

                }      </div>
  </div>
  
  
 <br/>
 </div>
 
    </div>
    </div>

    
    <div className='row margintop2'></div>
<br/>

  </div>
</div>
<div className='row'>
      <div className='col-5'></div>
      <div className='col-2'><button className='backbtn margbottom' onClick={redirect}>Back</button></div>

      <br/>
      <div className='col-5'></div>

    </div>
    <div className='row margtop1'></div>


  </div>


  <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div> 
  </div>
  );
}
 
export default ViewPatientProfile;