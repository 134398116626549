// import React from 'react';
// import './Login.css';
// import thyroidlogo from '../../images/thyroidlogo.jpg';
// import loginimg from '../../images/Login.jpg';
// import { useHistory } from "react-router-dom";
// import { useState ,useEffect } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
 
// const Login = ()=>{
//     let history = useHistory();
//     let isvalid= true;
//     const initialValues = { email: "",  password: "" };
//   const [formValues, setFormValues] = useState(initialValues);
//   const [formErrors, setFormErrors] = useState({});
//   const [isSubmit, setIsSubmit] = useState(false);

//   const [passwordShown, setPasswordShown] = useState(false);

//   const togglePasswordVisiblity = () => {
//     setPasswordShown(passwordShown ? false : true);
//   };

//   const[email, setEmail]=useState("");
//   const[password,setPassword]=useState("");
//   const[userValidEroor,setuserValidError]=useState(false);

//   // const [role,setRole] = useState("");
  
  
  

//   const handleEmail =(e)=>{
//     const { name, value } = e.target;
//     setFormValues({ ...formValues, [name]: value });
//      setEmail(e.target.value)
//   }
//   const handlePassword =(e)=>{
//     const { name, value } = e.target;
//     setFormValues({ ...formValues, [name]: value });
//     setPassword(e.target.value)

//   }

//    const handleSubmit = (e) => {
//     e.preventDefault();
//     setFormErrors(validate(formValues));
//     console.log(formErrors);
//     console.log(email,password);
//     const response = axios.post('http://localhost:3004/UsersAuth', user, )
//     response.then(() => {
//       //  alert("data added")
//       toast.success("data  has been added Successfully!");

//     })
//     response.catch((err) => {
//       alert("Getting error in featching data")
//     });
//     axios.post("http://localhost:3004/UsersAuth",{
//       emailId: email,
//       password: password
//     })
//     .then(response =>{
//       if(email=="jadhavashvini032@gmail.com" && password=="Ashvini123"){
//         history.push("Dashboard");

//         setuserValidError(false)
//       }
//       else{
//         //toast.warn("Invalid user");
//         setuserValidError(true)
//       }
//     })
//     .catch(error =>{
//       console.log(error)
//     })
    
    
//     setIsSubmit(true);

//   };

//   useEffect(() => {
//     console.log(formErrors);
//     if (Object.keys(formErrors).length === 0 && isSubmit) {
//       console.log(formValues);
//     }
//   }, [formErrors]);

//   const validate = (values) => {
//     const errors = {};
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
//     if (!values.email) {
//       errors.email = "Email is required!";
//       isvalid=false;
//     } else if (!regex.test(values.email)) {
//       errors.email = "This is not a valid email format!";
//       isvalid=false;

//     }
//     if (!values.password) {
//       errors.password = "Password is required !";
//       isvalid=false;

//     } else if (values.password.length < 4) {
//       errors.password = "Password must be more than 4 characters";
//       isvalid=false;

//     } else if (values.password.length > 20) {
//       errors.password = "Password cannot exceed more than 20 characters";
//       isvalid=false;

//     }
    
    
    
//     return errors;
//   };

//     return(
//         <div className=" loginrow row">
//             <div className=" Logining col-6">
//                 <img src={loginimg} width={790} height={850}/>
//                 </div>
//                 <div className="col-6">
//                   <div className="loginlogo">
//                     <img className="" src={thyroidlogo} width={210} height={130} />
//                   </div>
//                   <div className="welcometext">
//                     <span>Welcome to The Ayurvedic T.H.Y.R.O.I.D. Clinic </span>
//                     <div> <span>Health Calculator</span>
//                     </div>
//                   </div>
//                 <form>
//                     <div className="main-div">
//                         <h4 className="welcome">Login</h4>
//                     <div className=" usernme form-group">
//                         <div className="ulable">
//                         <label className=""> Email Id
//                         </label>
//                          </div> 
                        
//                   <div class="inputWithIcon">
//     <input type="email" className="place" name="email"  value={formValues.email} onChange={handleEmail}/>
  
//    </div>
//    <p className="redp">{formErrors.email}</p>

//                     </div>

//                     <div className="form-group">
//                         <div className="ulable">
//                         <label> Password
//                         </label>
//                         </div>
//                         <div class="inputWithIcon">
//     <input   className="place"  type={passwordShown ? "email" : "password"}
//    name="password"    value={formValues.password} onChange={handlePassword}/>

  
//    <i class="show1 fa fa-eye fa-lg fa-fw" onClick={togglePasswordVisiblity} aria-hidden="true"></i> 

//    </div>
//    <p className="redp">{formErrors.password}</p>

                       
//                     </div>
//                     <lable className={userValidEroor ? "show lablered":"hide"}>Invalid User</lable>


//                 <div className="chek row">
//                     <div className="col-5">
//                 <input type="checkbox" className="remember" /><span className="">Remember Me</span>
//                 </div>
//                 <div className="forgot col-5">
//                 <Link  to="/ForgotPassword"><span className="forgotpasstxt">Forgot Password?</span></Link>
//                 </div>
//                 <div className="col-1"> </div>
//                 </div><br/><br/>
//                 <div className="sign form-group">
//                 <button 
//                 class="sub" onClick={handleSubmit} style={{fontFamily: "open sans"}}>Login</button>
//                 </div>
//                 </div>

//                 </form>
//                     </div>


//         </div>
//     )
// }
 
// export default Login;
import React from 'react';
 import './Login.css';
 import thyroidlogo from '../../images/thyroidlogo.jpg';
 import thyroid from '../../images/thyroid1.jpg';
 import { useHistory } from "react-router-dom";
 import { useState ,useEffect } from "react";
 import { Link } from "react-router-dom";
 import axios from "axios";
 import { makeStyles } from "@material-ui/core/styles";

 import { InputAdornment } from "@material-ui/core";
import {Grid , Paper, Avatar,TextField,Button,Typography,FormControl,OutlinedInput,InputLabel ,IconButton} from '@material-ui/core';
import {MdVisibility} from 'react-icons/md';
import {AiFillEyeInvisible} from 'react-icons/ai'
import {MdEmail} from 'react-icons/md'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.white,
    color:theme.palette.common.white,
  },
  input: {
    color: theme.palette.common.white,

    "&:hover $notchedOutline": {
      borderColor: theme.palette.grey[400]
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.grey[400]
    },
  },
  notchedOutline: {
    borderColor: theme.palette.grey[400]
  },
  focused: {},
  adornedStart: {
    color: theme.palette.grey[400]
  }
}));

const Login = ()=>{

  //for checksession


 
 
  

  const textfieldstyle={}
  const classes = useStyles();


    let history = useHistory();
    let isvalid= true;
    const initialValues = { email: "",  password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const[userValidEroor,setuserValidError]=useState(false);


  
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });
  const handleChange =(prop) =>(e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setValues({ ...values, [prop]: e.target.value });

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formErrors);
    if(isvalid ){
      debugger
    axios.post("/userAuthentication",{
      emailId: values.email,
      password: values.password
      
    })
    .then(result =>{
      console.log("authdata",result.data.data);
      debugger;
      if(result.data.data !=null){
        history.push("/PatientList");

        setuserValidError(false)
      }
      else{
        setuserValidError(true)
      }
    })
    .catch(error =>{
      console.log(error)
    })
  }

    // if(isvalid ){
    //         history.push("/PatientList");

    // }
    setIsSubmit(true);

  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
    if (!values.email) {
      errors.email = "Email is required!";
      isvalid=false;
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
      isvalid=false;

    }
    if (!values.password) {
      errors.password = "Password is required !";
      isvalid=false;

    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
      isvalid=false;

    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
      isvalid=false;

    }
    // if (values.email =="admin@123gmail.com" && values.password == "123456") {
    //     isvalid = true;
    //   } 
    //   else{
    //     errors.password = "Username and Password  is not valid!";
    //     isvalid = false;

    //   }
    
    
    return errors;
  };

    return(
        <div className=" loginrow1 row">
            <div className=" Logining1 col-6">
              <div className='logimgborder'>
              <img src={thyroid} width={550} height={450} className="logimg1"/>

              </div>
                </div>
                <div className="col-6">
                   <div className="loginlogo">
                    {/* <img className="" src={thyroidlogo} width={210} height={130} /> */}
                  </div>
                  <br/> 
                  <div className="welcometext">
                  <span>Welcome to The Ayurvedic T.H.Y.R.O.I.D. Clinic </span>
                     <div> <span>Health Calculator</span>
                    </div>
                  </div>
                  <br/>
                <form onSubmit={handleSubmit}>
                    <div className="main-div">
                        <h4 className="welcome">Login</h4>
                        <br/>
                    <div className=" usernme form-group">
                         
  {/* <TextField label='E-mail' placeholder='Enter username' 
variant="outlined"   required 
name="email" 
className='textfieldmui'
value={formValues.email} 
onChange={handleChange}
  style={textfieldstyle}
  
  />   */}
  <FormControl sx={{ m: 0, width: '27ch' }} variant="outlined" size='small'>
          <InputLabel htmlFor="outlined-adornment-password" className='passlb'>Email</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            size="small"
            name="email"
            classes={{
              root: classes.input,
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
              adornedStart: classes.adornedStart
            }}
            className='textfieldmui'
            value={values.email}
            onChange={handleChange('email')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <MdEmail color='#ffffff'/> 
                </IconButton>
              </InputAdornment>
            }
            label="Email"
          />
        </FormControl>
                 
   <p className="redplog">{formErrors.email}</p>

                    </div>

                    <div className="form-group">
                        
                        
   {/* <TextField label='Password' placeholder='Enter password' 
variant="outlined"  
name="password"
className='textfieldmui'
 onChange={handleChange}
type={password ? 'text' : 'password'}

  required style={textfieldstyle}
 InputProps={{
    startAdornment: (
      <InputAdornment position="start">
          
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
          <IconButton
                aria-label="toggle password visibility"
                onClick={handleTogglePassword}
                >
                    {password ? <MdVisibility /> : <AiFillEyeInvisible />}
                </IconButton>
            

      </InputAdornment>
    )
  }}
 /> */}
  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" size='small'>
          <InputLabel htmlFor="outlined-adornment-password" className='passlb'>Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            classes={{
              root: classes.input,
              notchedOutline: classes.notchedOutline,
              focused: classes.focused,
              adornedStart: classes.adornedStart
            }}
            className="textfieldmui"
            size="small"
            name='password'
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {values.showPassword ? <AiFillEyeInvisible color='#ffffff'/>: <MdVisibility color='#ffffff'/>}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
   <p className="redplog">{formErrors.password}</p>

                       
                    </div>


                <div className="chek row">
                    <div className="col-5">
                </div>
                <div className="forgot col-5">
                <Link  to="/Forgot"><span className="forgotpasstxt">Forgot Password?</span></Link>
                </div>
                <div className="col-1"> </div>
                </div><br/><br/>
                <div className="sign form-group">
                <button 
                class="sub" >Login</button>
                </div>

                <lable className={userValidEroor ? "show lablered":"hide"}>Invalid User</lable>

                </div>

                </form>
                    </div>


        </div>
    )
}
export default Login