import Sidebar from '../Sidebar';
import React, { useState, useEffect ,useRef} from "react";
import {RiMenuLine} from 'react-icons/ri';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {ImDownload2} from 'react-icons/im'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {FiFilter} from 'react-icons/fi';
import FilterPopup from './FilterPopup';
import Footer from "../Footer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";


 
const PatientList=()=>{
  const [session ,setSession] = useState(false);
  let history = useHistory();

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
  
  
  const [ fileData, setFileData ] = useState();

  const fileHeaders = [
    { label: "Id ", key: "id" },
    { label: "Patient Name", key: "name" },
    { label: "Contact No.", key: "contactNumber" },
    { label: "HC Name", key: "calculatorName" },
    { label: "Campaign Name", key: "campaignName" },
    { label: "Campaign Date", key: "date" },
    { label: "BMI score", key: "bmi" },
    { label: " Score", key: "score" },
    { label: " City", key: "city" },


];

    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }
     const ref = useRef(null);


      const columns = [
  
    
      {
        name: "Sr.No.",
       selector: "id",
        sortable: true,
        width:"70px"

        
    },
    {
      name: "Patient Name",
     selector: "name",
      sortable: true,
       cell: (d) => (
           <Link to={`/ViewPatientProfile/${d.id}`}  className="dlink texttransformcapitalize">
              {d.name}
            </Link>
          )
      
  },
  {
      name: "Contact No.",
     selector: "contactNumber",
      sortable: true,
     className:"texttransformcapitalize"
      
  },
  {
      name: " HC Name",
     selector: "calculatorName",
      sortable: true,
      className:"texttransformcapitalize"

      
  },
  {
      name: "Campaign Name",
     selector: "campaignName",
      sortable: true,
      className:"texttransformcapitalize"

      
  },
  {
      name: "Campaign Date",
     selector: "date",
      sortable: true,
      className:"texttransformcapitalize"

      
  }
  ,{
      name: " BMI Score",
     selector: "bmi",
      sortable: true,
      width:"89px",
      className:"texttransformcapitalize"


  },
  {
    name: "  Score",
   selector: "score",
    sortable: true,
    width:"89px",
    className:"texttransformcapitalize"


    
  },
  {
    name: "  City",
   selector: "city",
    sortable: true,
    width:"89px",
    className:"texttransformcapitalize"


    
  },
  {
    name: " Conversion",
   selector: "conversion",
    sortable: true,
    width:"79px",

    cell: (d) => {
        
    return(
    //   <div id={d.id} ref={ref} className={d.conversion==1?  'checkbox checkbox--on':"checkbox"} onClick={() => setCheckConver(d.id,ref)}
    // >
    //   <div className="checkbox__ball"></div>
    //   {/* <span className="checkbox__text">{value ? "Yes" : "No"}</span> */}
    // </div>

<label className="switch"  >
<input type="checkbox" name={d.id}  ref={ref}  checked={d.conversion==1? true:false} onChange={setCheckConver} />
<div className="slider"></div>
</label>
    )
    }
    
  },
      
    
      
    ];
   

    


    const setCheckConver = (e) => {
      debugger;
      if(e.target.checked==true){
         e.target.checked=true;
        
      }
     var patientId=e.target.name;
      const response = axios.post('/patient/conversion?patientId=' +patientId)
      response.then(() => {
          //alert("data added")
         toast.success("conversion  has been added Successfully!");
         loadUsers();
      
      })
      response.catch((err) => {
        alert("Getting error in featching data")
      });
    };
    


    // useEffect(() => {
    //   console.log('className ', ref.current.className);
  
    //   //  check if element contains class
    //   if (ref.current.classList.contains('checkbox--on')) {
    //     console.log('Element contains class');
    //   } else {
    //     console.log('Element does NOT contain class');
    //   }
    // }, []);

     const [data, setData] = useState([]);
    const tableData = {
      columns,
      data,
      
    };

    useEffect(() => {
      loadUsers();
    },[]);
  
    const loadUsers = async () => {
      const result = await axios.get("/patient/getAllPatients");

      setData(result.data.data);
      console.log("patientlist",result.data.data)
      setFileData(result.data.data);

      localStorage.setItem("patientData", JSON.stringify(result.data.data))

    };

    
  const [searchDiv ,setSearchDiv] = useState(false);
  function searchpoupopen (isSearch){
    if(isSearch){
      setSearchDiv(true);
    }
    else{
      setSearchDiv(false);
    }
  }

  const [isfilterdata, setisfilterdata] = useState(
    {
      PCOS:"",
      Thyroid: "",
   }
  );
  
  const handleChange = (e) => {
    
    debugger
    if(e.target.checked==true && e.target.name=='PCOS'){
      setisfilterdata(preState => ({
        ...preState,
        "PCOS": 'PCOS'
      }))
    }
    else{
      setisfilterdata(preState => ({
        ...preState,
        "PCOS": ''
      }))
    }
    if(e.target.checked==true && e.target.name=='Thyroid'){
      setisfilterdata(preState => ({
        ...preState,
        "Thyroid": 'Thyroid'
      }))
    }
    else{
      setisfilterdata(preState => ({
        ...preState,
        "Thyroid": ''
      }))
    }


   }


  
  const onFilter = async () => {
var url;
    debugger
    
   var url= '/patient/getAllFilteredPatients?calculators='+isfilterdata.PCOS+','+isfilterdata.Thyroid;
      const result = await axios.get(url);
      setData(result.data.data);
      console.log("serchpatent",result.data.data)
      setFileData(result.data.data);
      searchpoupopen(false);
  };
  
  const showAllDetails =async()=>{
    var url= '/patient/getAllFilteredPatients';
      const result = await axios.get(url);
      setData(result.data.data);
      console.log("serchpatent",result.data.data)
      setFileData(result.data.data);
      searchpoupopen(false);
  }

  const [hc ,setHC] = useState(false);
  const [all ,setAll] = useState(false);

  
  
  const showHC =()=>{
    setHC(true);
    setAll(false);
    }
    const showAll =()=>{
      setHC(false);
      setAll(true);
      showAllDetails();
      }
   

  return (
    <div>
 {/* <div className={session == true?"":"hide"}>   */}

<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Patient List</span>
          
         </div><br/><br/><br/>
         <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'> Patient List</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>  
            <div className="clientbtn row">
            <div className="col-1">
              <FiFilter size='1.4em' className='filtericon' onClick={()=>searchpoupopen(true)}/>
            </div>
            

              <div className="col-10">
         <span className="adclptag">Patient List</span>
         </div>
         <Tippy content="download">
        <div className="col-1">

         {fileData?.length &&
      <CSVLink
        headers={fileHeaders}
        data={fileData}
        filename="PatientList.csv"
        target="_blank"
      > 
      <ImDownload2 size="1.5em" className="iodownload"/>     
         
     </CSVLink> 
     } 
             </div>
 </Tippy>
        

         <div className="main">
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={false} //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
          

        />
      </DataTableExtensions>
      <div className='row margintop2'></div>
      <div className='row margintop2'></div>

    </div>
   
    <div className={searchDiv ? "row":"hide row"}>
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={()=>searchpoupopen(false)}>x</span>
       
      <div className="filterbydiv">
      <span className="filterby">Filter by</span>
      <span className="clearfilter">Clear</span>
     </div>
<br/>
<div className="row">
<div className="col-6 txtLeft">
  <div className="all" onClick={showAll}>All</div>
  <div onClick={showHC} className={hc ? "availblactive":"inactiv"}> Health Calculator</div>
  </div>
  <div className={ hc ? "col-6 secpopmenudiv" :"hide"}>
    
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange} name='PCOS'/><span className="secspan">PCOS</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange} name='Thyroid'/><span className="secspan">Thyroid</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC3</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC4</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC5</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC6</span></div>

</div>
  </div>







<br/>
        <div className="filterbtns row">
        <div className="col-6"><button className="filtcancel" onClick={()=>searchpoupopen(false)}>Cancel</button></div>
        <div className="col-6"><button className="filtaply" onClick={onFilter}> Apply</button></div>
        </div>

        

     
      </div>
    </div>
      </div>
    </div>
</div>
<br/>

 <div className={isExpanded ? " usermntmgtfooterexp" : " usermngmntfootercpls"}>  
   <Footer/> 
  </div>  
         </div>
         </div>
    //  </div>
    
           );
}
 
export default PatientList;