import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';
import { Link ,NavLink } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom"; 
import {FaEdit} from "react-icons/fa"
import Campaign from './Campaign';
import axios from "axios";
import Footer from "../Footer";


const ViewCoupon=()=>{

  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
    const [coupon ,setCoupon] = useState({
        id:"",
        campaignId:"",
        campaignName:"",
        startDate:"",
        endDate:"",
        calculatorName:"",
        createdDate: "",
        couponCodeNo:"",
        status:""
        });

        const {id} = useParams();
        useEffect(()=>{
            loadCoupon()
        },[])
    
    const loadCoupon = async () =>{
        debugger;

        const res = await axios.get(`/campaign/getCouponById?campaignId=${id}`);
        console.log("coupon",res.data.data)
        
          setCoupon(res.data.data);

    }
    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();

     const redirect =()=>{

        history.push("/Campaign");
       }

  return (
<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Campaign</span>
        </div><br/><br/><br/>

        <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Campaign/Coupon/ View Coupon</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>
          <div className='row'>
          <div className='col-3'></div>
         <div className="clientbtn  col-6">
            <div className="row displyflex">
            <div className='col-3'></div>
              <div className='col-6'>
                <span className="adclptag">View Coupon </span>
                 </div>
                 <div className='col-1'></div>

                 <div className='col-2'>
                 <Tippy content="Edit">
                 <NavLink className="" exact to={`/EditCoupon/${coupon.campaignId}`}>
            <FaEdit color='#A47355' size="1.6em" className='editicon'/>
            </NavLink>
            </Tippy>
            </div>
            </div>
            </div> 
         <div className='col-2'></div> 
        </div> 

<br/><br/><br/>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Coupon Code No:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.couponCodeNo}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Offer:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.offer}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Created Date:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.createdDate}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Campaign Name:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.campaignName}</span></div>
      <div className='col-2'></div>
</div>

<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Start Date:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.startDate}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">End Date:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.endDate}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Health Calculator Name:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.calculatorName}</span></div>
      <div className='col-2'></div>
</div>
<div className='form-group row'>
     <div className='col-3'></div>
     <div className='textright fontsize16 col-3'>
      <label for="campaignname">Status:</label>
      </div>
      <div className='col-4 textleft margintop6 colorfent'><span>{coupon.status}</span></div>
      <div className='col-2'></div>
</div>

<br/><br/><br/>
<div className='row'>
      <div className='col-5'></div>
      <div className='col-2'><button className='backbtn ' onClick={redirect}>Back</button></div>
      <br/>
      <div className='col-5'></div>

    </div>
<br/><br/><br/>

</div>

<div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div> 
</div>
  );
}
 
export default ViewCoupon;