import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data3";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {ImDownload2} from 'react-icons/im'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {FiFilter} from 'react-icons/fi';
import FilterPopup from '../PatientList/FilterPopup';
import { MdOutlineAddBox } from "react-icons/md";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";



 
const Campaign=()=>{

  const [session ,setSession] = useState(false);

  const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
           history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

         console.log("checksession on campaign",session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
  

  const [ fileData, setFileData ] = useState();

  const fileHeaders = [
    { label: "Id ", key: "id" },
    { label: "Campaign Name", key: "name" },
    { label: " Created date", key: "createdDate" },
    { label: "Start date", key: "startDate" },
    { label: "End date", key: "endDate" },
    { label: "HC name", key: "calculatorName" },
    { label: "Coupon code", key: "couponcode" },
    { label: " Total visitors", key: "visitors" },
    { label: "Status", key: "status" },


];

    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     const [data, setData] = useState([]);
    const tableData = {
      columns,
      data,
      
    };

    useEffect(() => {
      loadUsers();
    }, []);
  
    const loadUsers = async () => {
      const result = await axios.get("/campaign/getAllCampaign");
      debugger;
      setData(result.data.data);
      setFileData(result.data.data);

    };

    const [searchDiv ,setSearchDiv] = useState(false);
    function searchpoupopen (isSearch){
      if(isSearch){
        setSearchDiv(true);
      }
      else{
        setSearchDiv(false);
      }
    }
  
    const [isfilterdata, setisfilterdata] = useState(
      {
        PCOS:"",
        Thyroid: "",
     }
    );
    
    const handleChange = (e) => {
      
      debugger
      if(e.target.checked==true && e.target.name=='PCOS'){
        setisfilterdata(preState => ({
          ...preState,
          "PCOS": 'PCOS'
        }))
      }
      else{
        setisfilterdata(preState => ({
          ...preState,
          "PCOS": ''
        }))
      }
      if(e.target.checked==true && e.target.name=='Thyroid'){
        setisfilterdata(preState => ({
          ...preState,
          "Thyroid": 'Thyroid'
        }))
      }
      else{
        setisfilterdata(preState => ({
          ...preState,
          "Thyroid": ''
        }))
      }
  
  
     }
  
  
    
    const onFilter = async () => {
  var url;
      debugger
      
     var url= '/campaign/getAllFilteredCampaign?calculators='+isfilterdata.PCOS+','+isfilterdata.Thyroid;
  
        const result = await axios.get(url);
        setData(result.data.data);
        console.log("serchpatent",result.data.data)
        setFileData(result.data.data);
        searchpoupopen(false);
    };
    
    const showAllDetails =async()=>{
      var url= '/campaign/getAllFilteredCampaign';
        const result = await axios.get(url);
        setData(result.data.data);
        console.log("serchpatent",result.data.data)
        setFileData(result.data.data);
        searchpoupopen(false);
    }
  
    const [hc ,setHC] = useState(false);
    const [all ,setAll] = useState(false);

    
    const showHC =()=>{
      setHC(true);
      setAll(false);

      }

      const showAll =()=>{
        setHC(false);
        setAll(true);
        showAllDetails();
        }
     
      let history = useHistory();

      const redirectAddCamp =()=>{

        history.push("/AddCampaign");
       }
     

  return (
    // <div className={session == true?"":"hide"}>  
      <div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Campaign</span>
        </div><br/><br/><br/>

        <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Campaign/ Campaign List</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>  
            <div className="clientbtn row">
            <div className="col-1">
              <FiFilter size='1.4em' className='filtericon' onClick={()=>searchpoupopen(true)}/>
            </div>
            

              <div className="col-10">
         <span className="adclptag">Campaign List</span>
         <Tippy content="Add">
         <span className="addicon" onClick={redirectAddCamp}>
          <MdOutlineAddBox  size= "1.7em"  color="#A47355"/></span>
          </Tippy>
         </div>
         <Tippy content="download">
        <div className="col-1">

         {fileData?.length &&
      <CSVLink
        headers={fileHeaders}
        data={fileData}
        filename="CampaignList.csv"
        target="_blank"
      > 
      <ImDownload2 size="1.5em" className="iodownload"/>     
         
     </CSVLink> 
     } 
             </div>
 </Tippy>
        

         <div className="main">
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={false} //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
          

        />
      </DataTableExtensions>
      <div className='row margintop2'></div>
      <div className='row margintop2'></div>
    </div>
    <div className={searchDiv ? "row":"hide row"}>
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={()=>searchpoupopen(false)}>x</span>
       
      <div className="filterbydiv">
      <span className="filterby">Filter by</span>
      <span className="clearfilter">Clear</span>
     </div>
<br/>
<div className="row">
<div className="col-6 txtLeft">
<div className="all" onClick={showAll}>All</div>
  <div onClick={showHC} className={hc ? "availblactive":"inactiv"}> Health Calculator</div>
  </div>
  <div className={ hc ? "col-6 secpopmenudiv" :"hide"}>
    
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange} name='PCOS'/><span className="secspan">PCOS</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange} name='Thyroid'/><span className="secspan">Thyroid</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC3</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC4</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC5</span></div>
<div className="row secpopmenu"><input type="checkbox" className="filterchek" onChange={handleChange}/><span className="secspan">HC6</span></div>

</div>
  </div>







<br/>
        <div className="filterbtns row">
        <div className="col-6"><button className="filtcancel" onClick={()=>searchpoupopen(false)}>Cancel</button></div>
        <div className="col-6"><button className="filtaply" onClick={onFilter}> Apply</button></div>
        </div>

        

     
      </div>
    </div>
      </div>
    </div>
</div>
<div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div>  
         </div>
    // </div>

           );
}
 
export default Campaign;