import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';

import axios from "axios";
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { duration } from 'moment'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "../Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


 
const AddCampaign=()=>{
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
//for date
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());



  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();


     const [dateValidEroor ,setDateValidEroor] = useState(false);
     const [enddateValidEroor ,setendDateValidEroor] = useState(false);



     const [campaign, setCampaign] = useState({
      id:"",
      campaignId:"",
      name:"",
      startDate:"",
      endDate:"",
      calculatorName:"",
      
 
     })

     const handleChangeDate =(e)=>{
      setStartDate(e);
        setCampaign(preState => ({
          ...preState,
          "startDate": (moment(e).format("DD MMM yyyy")),

        }))
     }
     const handleChangeDate1 =(e)=>{
      setStartDate1(e);
      setCampaign(preState => ({
        ...preState,
        "endDate": (moment(e).format("DD MMM yyyy"))

      }))
   }
 
     const handleChange = (e) => {
      debugger;
      const { name, value, type, checked } = e.currentTarget;
  
      setCampaign(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }))
     }
     
     


    

     useEffect(() => {
      console.log("new data", campaign)
  
    }, [campaign])
    
    const onSave=(e)=>{
  
      if(campaign.startDate==""){
        setDateValidEroor(true)
        return false;
      }else{
        setDateValidEroor(false)
  
      }
      if(campaign.endDate==""){
        setendDateValidEroor(true)
        return false;
      }else{
        setendDateValidEroor(false)
  
      }
      

      const response = axios.post('/campaign/addCampaignCode', campaign)
    response.then(() => {
        //alert("data added")
       toast.success("Campaign  has been added Successfully!");
    
    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
     history.push("/Campaign");
    console.log("response", response?.data)
    

    }
      
   
    const redirect =()=>{

    history.push("/Campaign");
   }

  return (

<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Campaign</span>
        </div><br/><br/><br/>

        <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Campaign/ Campaign List/ Add Campaign</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>
          <form onSubmit={onSave}>
          <div className='row'>
          <div className='col-3'></div>
         <div className="clientbtn col-7">
            <div className="">
            <span className="adclptag">Add Campaign </span>
         </div>
        </div> 
        <div className='col-2'></div>
     </div> 

<br/><br/><br/>
     <div className='form-group row'>
     <div className='col-2'></div>

      <div className='textright margintop5 fontsize16 col-3'>
      <label for="campaignname">Campaign Name:</label>
      </div>
      <div className='col-4'>
      <input  type="text" class="form-control input texttransformcapitalize" id="campaignname"  
      required           
      name='name' onChange={handleChange}
       placeholder="Campaign Name"/>

      </div>

      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Start Date:</label>
      </div>
      <div className='col-4'>
      {/* <input type="text" class="form-control" id="campaignname" placeholder="Campaign Name"/> */}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={value}
        name="startDate"
        formatDate={(date) => moment(date).format('dd MMM yyyy')}
        selected={value}

        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}

<DatePicker
          className="adreservdate"
          name='date'
           selected={startDate}
             onChange={date => handleChangeDate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd MMM yyyy"
          />
        <lable className={dateValidEroor ? "show lablered":"hide"}>Start Date are required</lable>

      </div>

      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">End Date:</label>
      </div>
      <div className='col-4'>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      className='datepick'
        label="Date"
        value={value1}
        name="endDate"
        format="DD MMM YYYY"
        // onChange={(newValue) => {
        //   setValue(newValue);
        // }}
        onChange={handleChangeDate1}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}
    <DatePicker
          className="adreservdate"
          name='endDate'
            selected={startDate1}
             onChange={date => handleChangeDate1(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate1}
            dateFormat="dd MMM yyyy"
          />
          <lable className={enddateValidEroor ? "show lablered":"hide"}>End Date are required</lable>

      </div>

      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Health Calculator Name:</label>
      </div>
      <div className='col-4'>
      <select  class="form-control input" id="exampleFormControlSelect1" size="auto" onChange={handleChange} 
     name='calculatorName' required>
      <option value="">Select</option>
      <option value="PCOS">PCOS</option>
      <option value="Thyroid">Thyroid</option>
      <option className='HC3'>HC3</option>
      <option className='HC4'>HC4</option>
      <option className='HC5'>HC5</option>
    </select>   
   
    </div>

      <div className='col-2'></div>
      </div>
<br/><br/><br/>
      <div className='row margleft1'>
        <div className='col-4'></div>
        <div className='col-2'>
          <button className='cancelbtn' onClick={redirect}>Cancel</button>
        </div>
        <div className='col-2'>
        <button className='savebtn' >Save</button>
        </div>
        <div className='col-3'></div>

      </div>
     
     
  
  
  </form>
         </div>
         <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div>  
         </div>
          
          );
}
 
export default AddCampaign;