
import React from "react";
import { Link, NavLink } from "react-router-dom";
export const columns = [
  
    
    {
      name: "Sr.No.",
     selector: "id",
      sortable: true,
      
  },
  {
    name: "Patient Name",
   selector: "name",
   className:"texttransformcapitalize"
,
    sortable: true,
     cell: (d) => (
         <Link to={`/ViewPatientProfile/${d.id}`}  className="dlink">
            {d.name}
          </Link>
        )
    
},
{
    name: "Contact No.",
   selector: "contactNumber",
    sortable: true,
   
    
},
{
    name: " HC Name",
   selector: "calculatorName",
    sortable: true,
    className:"texttransformcapitalize"

    
},
{
    name: "Campaign Name",
   selector: "campaignName",
    sortable: true,
    className:"texttransformcapitalize"

    
},
{
    name: "Campaign Date",
   selector: "date",
    sortable: true,
    
}
,{
    name: " BMI Score",
   selector: "bmi",
    sortable: true,
    
},
{
  name: "  Score",
 selector: "score",
  sortable: true,
  
},
{
  name: "  City",
 selector: "city",
  sortable: true,
  className:"texttransformcapitalize"

  
},
    
  
    
  ];
  export const data = [
    {
      id:1,
    patientname:"Ashvini",
    contactno:"5678675456",
    hcname:"PCOS",
    campaignname:"ABC",
   campaigndate:"01 Aug 2022",
    bmiscore:"20",
    score:"20",
    
          
          },
     
  
  ]
  