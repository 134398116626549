import React from "react";
import './ForgotsendPopup.css';


const ForgotsendPopup = props => {
  return (
    <div className="popup-box11">
      <div className="box11">
<div className="chekurmail">
    <span className="chekmailspn">Check Your email</span>
</div>
<div className="chekmailtxt">
       <span className="forgotpasspn"> We have send a password recovery  </span>
        <div><span className="forgotpasspn">instruction to your mail</span></div>
</div>

<div className="sign1 form-group">
                <button 
                class="okbtn" style={{fontFamily: "open sans"}} onClick={props.handleClose}>Ok</button>
                </div>

      </div>
    </div>
  );
};

export default ForgotsendPopup;