import React from "react";
import './Forgot.css';
import thyroid from '../../images/thyroid.jpg'
import { useHistory } from "react-router-dom";
import { useState ,useEffect } from "react";
import ForgotsendPopup from "./ForgotsendPopup";
import { InputAdornment } from "@material-ui/core";
import {Grid , Paper, Avatar,TextField,Button,Typography,FormControl,OutlinedInput,InputLabel ,IconButton} from '@material-ui/core';
import axios from "axios";


const Forgot = ()=>{

  const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

    let history = useHistory();
    let isvalid= true;
    const initialValues = { email: "",  password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [values, setValues] = React.useState({
    email: '',
  });
  const handleChange =(prop) =>(e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setValues({ ...values, [prop]: e.target.value });

  };

  const [emailValidEroor ,setemailValidEroor] = useState(false);


  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  
  const handleSubmit = async(e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    axios.get(`/sendResetPasswordEmail?emailID=${values.email}`)
      
    .then(result =>{
      if(result.data.code==200){
       setIsOpen(true);
       setemailValidEroor(false)
      }
      else{
        setemailValidEroor(true)

      }
      debugger;
      console.log(result.data.data);
    }).catch(error =>{
      console.log(error);
    })

    
    setIsSubmit(true);

  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
    if (!values.email) {
      errors.email = "Email is required!";
      isvalid=false;
    } 
    // else if (!regex.test(values.email)) {
    //   errors.email = "This is not a valid email format!";
    //   isvalid=false;

    // }
    
    return errors;
  };

    return(
        <div className=" loginrow row">
            <div className=" Logining col-6">
            <img src={thyroid} width={550} height={450} className="logimg"/>
                </div>
                <div className="col-6">
                  
                <form onSubmit={handleSubmit} >
                    <div className="main-div1">
                        <h4 className="welcome1">Forgot Password</h4>
                        <div className="spntxt">
                            <span className="forgotpasspn">Enter Your registered email below to receive password </span>
                                <div><span className="forgotpasspn">reset instruction</span></div>
                        </div>
                    <div className=" usernme1 form-group">
                         
                        
                  {/* <div class="inputWithIcon1">
    <input type="email" className="place" name="email"  value={formValues.email} onChange={handleChange}/>
  
   </div> */}
   <FormControl sx={{ m: 0, width: '27ch' }} variant="outlined" size='small'>
          <InputLabel htmlFor="outlined-adornment-password" className="emaillbl">Email</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password1"
            type="text"
            size="small"
            name="email"
            
            className='textfieldmui'
           // value={values.email}
           value={formValues.email}
            onChange={handleChange('email')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                className="xyztxt"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  @gmail.com 
                </IconButton>
              </InputAdornment>
            }
            label="Email"
          />
        </FormControl>
   <p className="redpforgot">{formErrors.email}</p>

                    </div>
                    <lable className={emailValidEroor ? "show lablered":"hide"}>User email not registered</lable>

                    


                
                <div className="sign1 form-group">
                <button 
                class="sub1"  >Send</button>
                </div>
                {isOpen && <ForgotsendPopup
      
      handleClose={togglePopup}
    />}


                </div>

                </form>
                    </div>


        </div>
    )
}
export default Forgot