import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri'; 
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {FiPrinter} from 'react-icons/fi'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";



const PcosHealthCalculator =()=>{
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true);
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();
     const {id} = useParams();


     const redirect =()=>{
      history.push("/Calculator");
     }

     const [calculator, setCalculator] = useState({
      id:"",
      calculatorName:"",
      date:"",
      totalCampaigns:"",
      totalConversions:"",
      totalVisitors:"",
      ongoingCampaigns: ""
      
      })


  const loadCalculator = async () =>{
      debugger;
      const res = await axios.get(`/calculator/getCalculatorDetails?calculatorName=${id}`);
      setCalculator(res.data.data);
      console.log("calname",res.data.data)
      
  }
  useEffect(()=>{
      loadCalculator()
  },[])
  
    

    return (
      <div className={ isExpanded ? " container" : "clpsMenu"}> 
      {/* <div className="container ">   */}
     <Sidebar isExpanded={isExpanded}></Sidebar>
     <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
     <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
     <span className="titlebar"> Calculators</span>
      </div><br/><br/><br/>
      <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Calculators/ Health Calculators/PCOS</span></div>

     <div className={isExpanded ? "usergrid" : "usergrid1"}>  
<div className='pcosspn'><span className='pcpstxt'>PCOS Health Calculator</span></div>

        <div className={calculator.date==''?'hide':''}><span className='dt'>DOA: {calculator.date}</span></div>
 

                  
<br/><br/><br/>
<div className='row '>
  <div className='col-1'></div>
  <div className='col-2 pcosbox'>
  {
                    calculator.totalCampaigns =='' && (
                      <div className='totlnum'><span className='num'>0</span></div>

                      )

                }  
                {
                    calculator.totalCampaigns !='' && (
                      <div className='totlnum'><span className='num'>{calculator.totalCampaigns}</span></div>

                      )

                }  
<div className='totltxt'><span className='txt'>Total Campaign</span></div>

</div>
  <div className='col-1'></div>
  <div className='col-2 pcosbox'>
  {
             calculator.totalVisitors =='' && (
                  <div className='totlnum'><span className='num'>0</span></div>

                      )

                }  
                {
                    calculator.totalVisitors !='' && (
                      <div className='totlnum'><span className='num'>{calculator.totalVisitors}</span></div>

                      )

                }  
<div className='totltxt'><span className='txt'>Total Visitors</span></div>

</div>  <div className='col-1'></div>

<div className='col-2 pcosbox'>
{
             calculator.totalConversions =='' && (
                  <div className='totlnum'><span className='num'>0</span></div>

                      )

                }  
                {
                    calculator.totalConversions !='' && (
<div className='totlnum'><span className='num'>{calculator.totalConversions}</span></div>

                      )

                }  
<div className='totltxt'><span className='txt'>Total Conversions</span></div>
</div>  <div className='col-1'></div>

<div className='col-2 pcosbox'>
{
             calculator.ongoingCampaigns =='' && (
                  <div className='totlnum'><span className='num'>0</span></div>

                      )

                }  
                {
                    calculator.ongoingCampaigns !='' && (
<div className='totlnum'><span className='num'>{calculator.ongoingCampaigns}</span></div>

                      )

                }  
<div className='totltxt'><span className='txt'>Ongoing Campaigns</span></div>
</div>
</div>
<br/>
     <div className="clientbtn row">
            <div className="col-12">
           <span className="adclptag"> Questionnaire</span>
           </div>
         </div>
<br/><br/>
    <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>1.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	do you experience any changes in your weight?</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="changeinweight" 

         id="exampleRadios1" value="excessweightgainloss"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios1">
       	Excess weight gain/loss                      
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="changeinweight"

         id="exampleRadios2" value="moderateweightgainloss"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios2">
       	Moderate weight gain/loss          
       </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="changeinweight" id="exampleRadios3"

         value="nochange"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios3">
      	No any change                                           
        </label>
        <div className='col-2'></div>
        </div>
        

        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>2.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	Do you experience any swelling?</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" 
          name="swelling" id="exampleRadios4" value="wholebody"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios4">
      	Whole body    
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="swelling" 
         id="exampleRadios5" value="extermityonly"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios5">
      	Face /extremity only
       </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="swelling" 

         id="exampleRadios6" value="no"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios6">
       	No any                                               
        </label>
        <div className='col-2'></div>
        </div>
        

        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>3.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	Do you experience complaint of constipation? </span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="constipation" id="exampleRadios7"

         value="oftenneedmedic"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios7">
       	Very often and need medication             
         </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="constipation" id="exampleRadios8"
         value="oftennoneedmedic"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios8">
      	Oftenly but need no medication       
       </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="constipation" id="exampleRadios9"
          value="duetodietchange"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios9">
      	Sometimes due to diet changes              
        </label>
        </div>
        

        <div className='row  margintop3'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>4.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	Do you have complaint of acidity?</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="acidity"
         id="exampleRadios10" value="oftenandneedmedication"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios10">
       Very often and need medication             
         </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="acidity" 
         id="exampleRadios11" value="oftenandneednomedication"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios11">
      	Oftenly but need no medication      
       </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="acidity" 
       id="exampleRadios12" value="dietchanges"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios12">
       	Sometimes due to diet changes            
        </label>
        </div>
        
        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>5.</div>
          <div className='col-6 textleft'><span className='fontsize16'>How is your sleep pattern?</span>
          </div>
         </div>
        
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="sleeppattern" id="exampleRadios19"
        value="insomnia"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios19">
       	Insomnia     
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="sleeppattern" id="exampleRadios20"      
          value="distributed"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios20">
       	Disturbed 
       </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="sleeppattern" 
          id="exampleRadios21" value="sound"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios21">
      	Sound   
        </label>
        <div className='col-2'></div>
        </div>
        
        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>6.</div>
          <div className='col-6 textleft'><span className='fontsize16'>Which of the following pschychological complaints do you experience more often? </span>
          <div className="asmnyas"> Select as many as</div>          
          </div>
         </div>

         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" name='irritation' type="checkbox"
 
         id="checkirrit" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="checkirrit">
        Irritation
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox"
 
        name="anxiety" id="chekanxity" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekanxity">
        	Anxiety
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox"
        name="depression" id="chekdepress" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekdepress">
        	Depression 
        </label>
        <div className='col-2'></div>
        </div>

        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="dullness" 
        id="chekdull" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekdull">
        	Dullness
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="moodswings"
        id="chekmodswing" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekmodswing">
        	Moodswings
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="cry"
        id="chekcry" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekcry">
        	Cry 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="foegtfulness" 
        id="chekforget" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekforget">
        	Foegetfulness
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="stress" 

        id="chekstress" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekstress">
        	Stress 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="no"

        id="chekno" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekno">
        No
        </label>
        <div className='col-2'></div>
        </div>
         
        
        
        <div className='row  margintop'>
          <div className='col-4'></div>
          <div className='col-1 textright fontsize16'>7.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	Do you experience any of the following physical complaints?</span>
          <div className="asmnyas"> Select as many as</div>          
          </div>
         </div>

         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="palpitation"
        id="chekpalpit" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekpalpit">
        	Palpitation
        </label>
        <div className='col-2'></div>
        </div>
        
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="fatigue"
        id="chekfatigue" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekfatigue">
        	Generalised weakness/fatigue 
        </label>
        <div className='col-2'></div>
        </div>

        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="mildextension"
        id="chekmildexten" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-5 " for="chekmildexten">
        	Breathlessness on mild exertion 
        </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="skindry"
        id="chekskindry" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekskindry">
        	Skin dryness
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="musclescramp" 
        id="chekmusclesramp" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekmusclesramp">
        	Muscle cramps 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="hairfall" 
        id="chekhairfall" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekhairfall">
        	Hairfall
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="britnails"
        id="chekbritnail" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekbritnail">
        	Brittle nails 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="execsweating"
        id="chekexessswat" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekexessswat">
        	Excess sweating 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="buldgingeye"
        id="chekbulgingeye" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekbulgingeye">
        	Facial hairs   
        </label>
        <div className='col-2'></div>
        </div><div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="no1"
        id="chekno1" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekno1">
        No
        </label>
        <div className='col-2'></div>
        </div>     
        
        
        <div className='row  margintop '>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>8.</div>
          <div className='col-6 textleft'><span className='fontsize16'>How will you describe your mestruation</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="menstrualdescrip"
         id="exampleRadios22" value="iregularpainful"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios22">
      	Irregular  painfull/scanty/ heavy flow       
         </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="menstrualdescrip" 

         id="exampleRadios23" value="regularpainful"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios23">
       	Regular scanty flow/ painfull/ heavy flow   
       </label>
        </div>
        <div className=' margintop3 marginbottom row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="menstrualdescrip"

         id="exampleRadios24" value="regularnormalflow"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-5" for="exampleRadios24">
       Regular with normal flow/painless            
        </label>
        </div>
        
        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>9.</div>
          <div className='col-6 textleft'><span className='fontsize16'>	Which of the following complaints do you experince during or before periods?</span>
          <div className="asmnyas"> Select as many as</div>          
          </div>
         </div>

         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="palpitation"
        id="chekpalpit" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekpalpit">
        	Abdomen Cramps 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="tremors"
        id="chektremore" 
        />
       
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chektremore">
        	Mood Swings  
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="fatigue"
        id="chekfatigue" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekfatigue">
        	Leg pain/ Back pain  
        </label>
        <div className='col-2'></div>
        </div>

        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="mildextension"
        id="chekmildexten" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-5 " for="chekmildexten">
        	Muscle Cramps  
        </label>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="skindry"
        id="chekskindry" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekskindry">
        	Breast pain/heaviness 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="musclescramp" 
        id="chekmusclesramp" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekmusclesramp">
        	Acne  
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="hairfall" 
        id="chekhairfall" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekhairfall">
        	Fever/Loose motions/ Vomitting/Bloating 
        </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
        
        <input class="form-check-input borderdark" type="checkbox" name="britnails"
        id="chekbritnail" 
        />
        
       </div>
        <label class="form-check-label textleft  fontsize16  col-3 " for="chekbritnail">
        	No Any  
        </label>
        <div className='col-2'></div>
        </div>
          
        
       <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>10</div>
          <div className='col-6 textleft'><span className='fontsize16'>How will you describe your period flow</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="neckswelling"
      id="exampleRadios13" value="yesandpain"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios13">
      With clots/ Fowl smell/ Blackish/ Brownish color/ Burning sensation                                     
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="neckswelling" 
         id="exampleRadios14" value="yeswithnopain"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios14">
       Sometimes with clots/ No fowl smell/ Brownish color                              
       </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" 
        name="neckswelling" id="exampleRadios15" value="no"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios15">
       	Without clot/ No smell/ Fresh red   
        </label>
        <div className='col-2'></div>
        </div>
        
        
        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>11.</div>
          <div className='col-6 textleft'><span className='fontsize16'>Do you experience any vaginal discharge between periods?</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="throatinfect" id="exampleRadios16"

         value="recurrent"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios16">
       Regular/ Curdy/ Itching/Burning/Fowl smell       
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="throatinfect" id="exampleRadios17" 
          value="seasonal"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios17">
       Regular/Sticky/Watery/No smell/No itching 
       </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="throadtinfect" id="exampleRadios18"
         value="no"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios18">
       	Sometimes/Whitish /Never 
        </label>
        <div className='col-2'></div>
        </div>
        

        <div className='row  margintop'>
          <div className='col-3'></div>
          <div className='col-1 textright fontsize16'>12.</div>
          <div className='col-6 textleft'><span className='fontsize16'>Do you have issue for conceving?</span>
          </div>
         </div>
    
         <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="throatinfect" id="exampleRadios16"

         value="recurrent"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios16">
        Yes
         </label>
        <div className='col-2'></div>
        </div>
        <div className=' margintop3  row'>
         <div className='col-4'></div>
          <div className="col-1 textright">
         <input class="form-check-input " type="radio" name="throatinfect" id="exampleRadios17" 
          value="seasonal"  />
        </div>
       <label class="form-check-label textleft marginlft4 fontsize16 col-3" for="exampleRadios17">
        No
       </label>
        <div className='col-2'></div>
        </div>
        
        
  
        
        <div className="row margintop3"></div>
        
        
        
<br/><br/><br/>
<div className='row'>
      <div className='col-5'></div>
      <div className='col-2'><button className='backbtn margleft' onClick={redirect}>Back</button></div>
      <br/>
      <div className='col-5'></div>

    </div>
<br/><br/><br/>
     </div> 

     <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div>     
  </div>
  );
}
 
export default PcosHealthCalculator ;