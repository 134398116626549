import Sidebar from './Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';
import dinnerimg from '../images/campaign1.png';
import dinnerimg1 from '../images/user2.png';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

import axios from "axios";
import {FiUsers} from 'react-icons/fi';
import {Line} from 'react-chartjs-2';
import Footer from "./Footer";
import { useHistory } from "react-router-dom";

 
const Dashboard=()=>{

  const [session ,setSession] = useState(false);
  let history = useHistory();

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
  const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
  const handleToggler =()=>{
    if(isExpanded){
      setIsExpanded(false);
      localStorage.setItem('sidebar-collapsed',true);
     return;
   }
   setIsExpanded(true);
    localStorage.removeItem('sidebar-collapsed');
 }
  
 const [campdata, setCampData] = useState();
 useEffect(() => {
  loadCamp();
},[]);

const loadCamp = async () => {
  const result = await axios.get("/dashboard/campaignCount");

  setCampData(result.data.data);
  console.log("camp",result.data.data)


};
const [visitordata, setVisitorData] = useState();
 useEffect(() => {
  loadVisitor();
},[]);

const loadVisitor = async () => {
  const result = await axios.get("/dashboard/visitorsCount");

  setVisitorData(result.data.data);
  console.log("visitor",result.data.data)


};
const [ongoingCampdata, setongoingCampdata] = useState([]);
 useEffect(() => {
  loadOngoingCamp();
},[]);

const loadOngoingCamp = async () => {
  const result = await axios.get("/dashboard/listOfOngoingCampaigns");

  setongoingCampdata(result.data.data);
  console.log("campaign",result.data.data)


};

const [graphdata, setgraphdata] = useState();
const [ConversionsCount, setConversionsCount] = useState([0,0]);
const [CampaignsCount, setCampaignsCount] = useState([0,0]);
const [VisitorsCount, setVisitorsCount] = useState([0,0]);

 useEffect(() => {
  loadgrapgdata();
},[]);

const loadgrapgdata = async () => {
  const result = await axios.get("/dashboard/listOfCounts");

  if(result.data.data != null){

    setConversionsCount(result.data.data.countConversions);
    setCampaignsCount(result.data.data.countCampaigns);
    setVisitorsCount(result.data.data.countVisitors);
  
}

};

const [chartData, setChartData] = useState({});
const chart = () => {
  setChartData({
    labels: ['PCOS','Thyroid','HC3','HC4','HC5','HC6'],
    datasets: [
      {
      label: 'Campaigns',
      data: [39, 68, 49, 87, 69, 59, 111],

      backgroundColor: [
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',
        'rgba(0, 230, 19, 0.7)',



      ],
      borderColor: [
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
        'rgb(52, 199, 89)',
      ],
      borderWidth: 1
    },
    {
      label: 'Visitors',
      data: [35, 69, 36, 77, 59, 57, 99],
      backgroundColor: [
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',



      ],
      borderColor: [
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
        'rgb(255, 59, 48)',
      ],
      borderWidth: 1
    },
    {
      label: 'Conversions',
      data: [30, 59, 20, 61, 56, 55, 90],
      backgroundColor: [
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',



      ],
      borderColor: [
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
        'rgb(255, 165, 0)',
      ],
      borderWidth: 1
    }
  ],
  });
};
useEffect(() => {
  loadgrapgdata();
});
 
 return (
<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Dashboard</span>
          
         </div><br/><br/><br/>
         <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Dashboard</span></div>
         <br/>  
         
         <div className={isExpanded?"dashmain":"dashmainclpse"}>

         <div className=' row cardrow'>
         <div className={isExpanded?"maindivdash col-3 mr-4 ml-3 ":"maindivdashclpse col-3 mr-4 ml-3"} style={{float:'left'}}>
          <div className={isExpanded?"upperdiv row":"upperdivclpse row"}>
         <div className="col-4"><img className="dinerimg" src={dinnerimg}/></div>
         </div>
         <div className='middlediv'><span className='zero'>{campdata}</span></div>
          <div className="lowerrdiv row ">
          <div className="col-2"></div>
            <div className="col-8 zerodiv"><span className="totaltblespn">Total Campaigns</span></div>
            <div className="col-2"></div>
            </div>
        </div>


        <div className={isExpanded?"maindivdashred col-3 mr-4 ml-4 ":"maindivdashclpsered col-3 mr-4 ml-4"} style={{float:'left'}}>
          <div className={isExpanded?"upperdiv row":"upperdivclpse row"}>
         <div className="col-4"><img className="dinerimg" src={dinnerimg1}/></div>
         </div>
         <div className='middlediv'><span className='zero'>{visitordata}</span></div>
          <div className="lowerrdivred row">
          <div className="col-2"></div>
            <div className="col-8 zerodiv"><span className="totaltblespn">Total Visitors</span></div>
            <div className="col-2"></div>
            </div>
        </div>

        {/* <div className={isExpanded?"maindivdashred col-3 mr-4 ml-4  ":"maindivdashclpsered col-3 mr-4 ml-4"} style={{float:'left'}}>
        <div className={isExpanded?"upperdiv row":"upperdivclpse row"}>
         <div className="col-2"><img className="dinerimg" src={dinnerimg1}/></div>
         <div className="col-2 margintop1"><span className='hcnmespn'>PCOS</span></div>

         </div>
         <div className='middlediv'><span className='zero1'>Total Visitors</span>
         <div className=''><span className='zero2'>1500</span></div>
         </div>
          <div className="lowerrdivred1 row ">
            <div className="col-6 zerodiv"><span className="totaltblespn1">Campaigns 7</span></div>
            <div className="col-4"></div>
            </div>
        </div>
        
        <div className={isExpanded?"maindivdashred col-3 mr-4 ml-4 ":"maindivdashclpsered col-3 mr-4 ml-4"} style={{float:'left'}}>
          <div className={isExpanded?"upperdiv row":"upperdivclpse row"}>
         <div className="col-2"><img className="dinerimg" src={dinnerimg1}/></div>
         <div className="col-2 margintop1"><span className='hcnmespn'>Thyroid</span></div>

         </div>
         <div className='middlediv'><span className='zero1'>Total Visitors</span>
         <div className=''><span className='zero2'>1000</span></div>
         </div>
          <div className="lowerrdivred1 row ">
            <div className="col-6 zerodiv"><span className="totaltblespn1">Campaigns 5</span></div>
            <div className="col-4"></div>
            </div>
        </div> */}

          

          </div>  
          </div>

<br/><br/>
<div className={isExpanded?"dashmain":"dashmainclpse"}>
          <div className='row cardrow'>
            <div className='col-8 mr-4 ml-3 maingrapdiv'>
              <div className='healthgrptopdiv'><span className='healthgrpspn'>Health Calculator Graph</span></div>
            <div className='chartsdiv'>
              <Line
                    className='colorwhitback'
                      data={{labels: ['PCOS','Thyroid','HC3','HC4','HC5','HC6'],
                      datasets: [
                        {
                        label: 'Campaigns',
                        data: CampaignsCount,
                  
                        backgroundColor: [
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                          'rgba(0, 230, 19, 0.7)',
                  
                  
                  
                        ],
                        borderColor: [
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                          'rgb(52, 199, 89)',
                        ],
                        borderWidth: 1
                      },
                      {
                        label: 'Visitors',
                        data: VisitorsCount,
                        backgroundColor: [
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                  
                  
                  
                        ],
                        borderColor: [
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                          'rgb(255, 59, 48)',
                        ],
                        borderWidth: 1
                      },
                      {
                        label: 'Conversions',
                        data: ConversionsCount,
                        backgroundColor: [
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                  
                  
                  
                        ],
                        borderColor: [
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                          'rgb(255, 165, 0)',
                        ],
                        borderWidth: 1
                      }
                    ],}}
              
              height={360}
              width={600}
              options={{
                maintainAspectRatio:false,
              }}
              />
            </div>
            </div>
<br/><br/><br/>
            <div className='col-4  ml-4 maingrapdiv1'>
            <div className='healthgrptopdiv1'><span className='healthgrpspn1'>Ongoing Campaign Details</span></div>
   <br/>
   <div className='row ongpingdatarow'>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>Campaign Name</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>HC Name</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>Start Date</span></div>
   </div>
   {
 ongoingCampdata.map((getmenu,index)=>(
   <div className='row ongpingdatarow'>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>{getmenu.name}</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>{getmenu.calculatorName}</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>{getmenu.startDate}</span></div>
   </div>
 ))
}
{/* {
  ongoingCampdata && ongoingCampdata.length== 0&&(
    <div className='row ongpingdatarow'>
    
    <div className=' margintop1 textleft'><span className='headerspn'>There is no Ongoing Campaigns</span></div>
   </div>
  )
} */}

   {/* <div className='ongpingdatarow row'>
    <div className='col-4 margintop1 textleft '><span className='headerspn'>PQR</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>Thyroid</span></div>
    <div className='col-4 margintop1 textleft'><span className='headerspn'>25 Aug 2022</span></div>
   </div> */}
            </div>
            </div>
          <br/><br/>
          </div>
          <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div> 
  </div>
  );
}
 
export default Dashboard;