
import React from "react";
import { Link, NavLink } from "react-router-dom";
export const columns = [
  
    
    {
      name: "Sr.No.",
     selector: "id",
      sortable: true,
      
  },
  {
    name: "HC Name",
   selector: "calculatorName",
    sortable: true,
    className:"texttransformcapitalize",

    //  cell: (d) => (
    //      <Link to={`/PcosHealthCalculator/${d.id}`}  className="dlink">
    //         {d.hcname}
    //       </Link>
    //     )
        cell: (d) => (
          d.calculatorName=="PCOS"? <Link to={`/PcosHealthCalculator/${d.calculatorName}`} className="dlink ">{d.calculatorName}</Link>  :  <Link to={`/ThyroidHealthCalculator/${d.calculatorName}`} className="dlink ">{d.calculatorName}</Link>  

      )
    
},
{
    name: "DOA",
   selector: "date",
    sortable: true,
   
    
},
{
    name: "Total Campaigns",
   selector: "totalCampaigns",
    sortable: true,
    
},
{
    name: "Total Visitors",
   selector: "totalVisitors",
    sortable: true,
    
},
{
    name: "Total Conversions",
   selector: "totalConversions",
    sortable: true,
    
}
,{
    name: "Ongoing Campaigns",
   selector: "ongoingCampaigns",
    sortable: true,
    
}

    
  
    
  ];
  export const data = [
    {
      id:1,
      hcname:"PCOS",
      doa:"01 Aug 2022",
    hcname:"PCOS",
    totalcampaign:"ABC",
    totalvisitor:"15",
    totalconversion:"20",
    ongoingcampaign:"20",
    
          
          },
     
  
  ]
  