import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "../PatientList/Data1";
import axios from "axios";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {ImDownload2} from 'react-icons/im'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useHistory } from "react-router-dom";
import {FiFilter} from 'react-icons/fi';
import DateFilterPopup from './DateFilterPopup';
import Footer from "../Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'




const Report=()=>{
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
  const [ fileData, setFileData ] = useState();

  const fileHeaders = [
    { label: "Id ", key: "id" },
    { label: "Patient Name", key: "name" },
    { label: "Contact No.", key: "contactNumber" },
    { label: "HC Name", key: "calculatorName" },
    { label: "Campaign Name", key: "campaignName" },
    { label: "Campaign Date", key: "date" },
    { label: "BMI score", key: "bmi" },
    { label: " Score", key: "score" },
    { label: " City", key: "city" },


];

  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();


     const [data, setData] = useState([]);
    const tableData = {
      columns,
      data,
      
    };

    useEffect(() => {
      loadPatient();
    }, []);
  
    const loadPatient = async () => {
      const result = await axios.get("/patient/getAllPatients");

      setData(result.data.data);
      setFileData(result.data.data);

    };

const redirectToHC =()=>{
  history.push("/ReportHc")
}

const redirectToCamp =()=>{
  history.push("/ReportCampaign");

}
const redirectToPatient =()=>{
  history.push("/Report");

}

const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = () => {
     setIsOpen(!isOpen);
  }

  const [searchDiv ,setSearchDiv] = useState(false);
  function searchpoupopen (isSearch){
    if(isSearch){
      setSearchDiv(true);
    }
    else{
      setSearchDiv(false);
    }
  }

  const [isfilterdata, setisfilterdata] = useState();
  
  const handleChange = (e) => {
    
    if(e.target.value=="custom"){
      //setisfilterdata( e.target.value )
      setDateDiv(true);
    }
    else{
      setDateDiv(false);
    }

    setisfilterdata( e.target.value )

   }

const [reportNAme,setReportName] =useState("PatientReport.csv");
  
  const onFilter = async () => {
var url;
    debugger
    if(dateDataValue.enddate==''){
       url='/report/getFilteredPatientList?dateFilter='
      +isfilterdata+'&startDate='+dateDataValue.startdate;
      setReportName("PatientReport_"+dateDataValue.startdate+".csv");

    }else{
       url='/report/getFilteredPatientList?dateFilter='
      +isfilterdata+'&startDate='+dateDataValue.startdate+'&endDate='+dateDataValue.enddate;
      setReportName("PatientReport_"+dateDataValue.startdate+ "_" + dateDataValue.enddate+".csv");
    }
    

      const result = await axios.get(url);
      setData(result.data.data);
      console.log("serch",result.data.data)
      setFileData(result.data.data);
      searchpoupopen(false);
  };
  
  const [dateDiv ,setDateDiv] = useState(false);


  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const [dateDataValue, setdateDataValue] = useState({
    startdate:"",
    enddate: "",
 })
  
  const handleChangeDate =(e)=>{
    debugger;
    setdateDataValue(preState => ({
      ...preState,
      "startdate": (moment(e).format("DD MMM yyyy"))
    }))
    setStartDate(e);
    
   }
   const handleChangeDate1 =(e)=>{
    setdateDataValue(preState => ({
      ...preState,
      "enddate": (moment(e).format("DD MMM yyyy"))
    }))
    setStartDate1(e)
    
 }
 

  return (
<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar"> Reports</span>
          
         </div><br/><br/><br/>

     <div className='reportbtnlink margleft2 row'>
      <div className='col-2'><button className='patlinkbtn' onClick={redirectToPatient}>Patient List</button></div>
      <div className='col-2'><button className='hclinkbtn' onClick={redirectToHC}>Health Calculators</button></div>
      <div className='col-2'><button className='camplinkbtn' onClick={redirectToCamp}>Campaign</button></div>
     </div>
     <br/>

         <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'> Reports/Patient List</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}> 
         <div className="clientbtn row">
         <div className="col-1 paddingleft"></div>
         <div className="custfiltdiv  col-2">
              <div className='row'>
              <div className='col-3 nowraptxt'><span className=''>Custom Filter</span></div>
              <div className='col-6'></div>
               <div className='col-2 filterdiv'>
            <FiFilter size='1.4em' className='filtericon1' onClick={()=>searchpoupopen(true)}/> 
              </div>  
              </div>
              
              </div>
             

              <div className="col-8">
         <span className="adclptag">Patient List</span>
         </div>
         <Tippy className='margleft3' content="download">
        <div className="col-1">

         {fileData?.length &&
      <CSVLink
        headers={fileHeaders}
        data={fileData}
        filename={reportNAme} 
        target="_blank"
      > 
      <ImDownload2 size="1.5em" className="iodownload1"/>     
         
     </CSVLink> 
     } 
             </div>
 </Tippy>
        

         <div className="main">
      <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={false} //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
          

        />
      </DataTableExtensions>
      <div className='row margintop2'></div>
      <div className='row margintop2'></div>
    </div>


    <div className={searchDiv ? "row":"hide row"}>

    <div className="popup-boxdate">
      <div className="boxdate">
        <span className="close-icondate" onClick={()=>searchpoupopen(false)}>x</span>

<div className="maindtdiv">
    <div className="dtspn"><span className="">Date Filter</span></div>
    <br/>
<div class="form-group">
  <select class="fontsize14 form-control" id="date" name='datefilter' onChange={handleChange}>
  <option selected className="fontcolorlight fontsize14">Please Select</option>
  <option value="today" className="fontcolorlight fontsize14">Today</option>
  <option value="yesterday" className="fontcolorlight fontsize14">Yesterday</option>
  <option value="This week" className="fontcolorlight fontsize14">This week</option>
  <option value="This month" className="fontcolorlight fontsize14">This Month</option>
  <option value="This year" className="fontcolorlight fontsize14">This Year</option>
  <option value="custom" className="fontcolorlight fontsize14">Custom</option>
  </select>
</div>

<div className={dateDiv?"":"hide"}>
<div className='row'>
  <div className='col-5'>
  <label for="startdt">Start Date:</label>
  <div className=''>
  <DatePicker
          className="filterdropdate"
          name='date'
           selected={startDate}
             onChange={date => handleChangeDate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd MMM yyyy"
          />
  </div>

    </div> 
  <div className='col-1'></div>
   <div className='col-4'>
   <label for="enddt">End Date:</label>
  <div className=''>
  <DatePicker
          className="filterdropdate"
          name='endDate'
            selected={startDate1}
             onChange={date => handleChangeDate1(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate1}
            dateFormat="dd MMM yyyy"
          />
  </div>

   </div>

</div>
</div>

<br/><br/>
<div className="row">
    <div className="col-1"></div>
    <div className="col-3"><button className="datefiltcancel" onClick={()=>searchpoupopen(false)}>Cancel</button></div>
    <div className="col-2"></div>
<div className="col-3"><button className="datefiltaply" onClick={onFilter}>Apply</button></div>
</div>
<br/>

</div>

      </div>
    </div>

    </div>
    </div> 
         </div>
         <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div> 
         </div>
           );
}
 
export default Report;