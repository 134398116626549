import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';

import axios from "axios";
import { Link ,NavLink} from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment, { duration } from 'moment'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {MdDeleteOutline} from "react-icons/md";
import Footer from "../Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

 
const EditCoupon=()=>{
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());



  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();
     const {id} = useParams();


     const [campaign, setcampaign] = useState({
        id:"",
        campaignId:"",
        campaignName:"",
        startDate:"",
        endDate:"",
        calculatorName:"",
        couponCodeNo:"",
        offer:"",
        service:""
      
 
     })

     const handleChangeDate =(e)=>{
      setStartDate(e);
        setcampaign(preState => ({
          ...preState,
          "startDate": (moment(e).format("DD MMM yyyy")),

        }))
     }
     const handleChangeDate1 =(e)=>{
      setStartDate1(e);
      setcampaign(preState => ({
        ...preState,
        "endDate": (moment(e).format("DD MMM yyyy"))

      }))
   }
 
 
     const handleChange = (e) => {
      debugger;
      const { name, value, type, checked } = e.currentTarget;
  
  
      setcampaign(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }))
     }

      const [isdeletepopup,setisdeletepopup] = useState(false);

       const confirmDelete1 = async (id) => {
          debugger;
        const response = await axios.delete(`/campaign/deleteCoupon?id=${id}`).then
  debugger;
  toast.success("Campaign deleted succssefully");
  
  history.push("/Campaign");
      
      }


     
     useEffect(()=>{
        loadCampaign()
    },[])

const loadCampaign = async () =>{
    const res = await axios.get(`/campaign/getCouponById?campaignId=${id}`);
    setcampaign(res.data.data);
    console.log("couponeidtnyid",res.data.data)
    // setcampaign(preState => ({
    //     ...preState,
    //     "campaignId": res.data.data.id,
    //     "campaignName": res.data.data.name
    
    //   }))
}

     useEffect(() => {
      console.log("new data", campaign)
  
    }, [campaign])


    const onSave=()=>{
  debugger;
      const response = axios.post(`/campaign/editCoupon`, campaign)
    response.then(() => {
        //alert("data added")
       toast.success("Coupon  has been Updated Successfully!");
    
    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
     history.push("/Campaign");
    console.log("response", response?.data)
    
    }
      
   const redirect =()=>{

    history.push("/Campaign");
   }

    const deleteUser = () => {

     setisdeletepopup(true)

   }



  const redirectTo=()=>{
    debugger;
    history.push("/Campaign");
  }

  return (

<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Campaign</span>
        </div><br/><br/><br/>

        <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Campaign/ Coupon/ Edit Coupon</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>
         <form onSubmit={onSave}>


         <div className='row'>
          <div className='col-3'></div>
         <div className="clientbtn  col-6">
            <div className="row displyflex">
            <div className='col-3'></div>
              <div className='col-6'>
                <span className="adclptag">Edit Coupon </span>
                 </div>
                 <div className='col-1'></div>

                 <div className='col-2'>
                 <Tippy content="Delete">
             <Link className="" onClick={deleteUser}>
            <MdDeleteOutline color='#A47355' size="1.7em" className='editicon'/>
            </Link> 
            </Tippy>

            </div>
            </div>
            </div> 
         <div className='col-2'></div> 
        </div> 

<br/><br/><br/>
<div className='form-group row'>
     <div className='col-2'></div>

      <div className='textright margintop5 fontsize16 col-3'>
      <label for="couponName">Coupon Code No:</label>
      </div>
      <div className='col-4'>
      <input type="text" class="form-control input texttransformcapital" id="couponName" name='couponCodeNo' 
     value={campaign?.couponCodeNo}

      required
       onChange={handleChange} placeholder="TATC-0123456"/>
      </div>
      <div className='col-2'></div>
      </div>
     <div className='form-group row'>
     <div className='col-2'></div>

      <div className='textright margintop5 fontsize16 col-3'>
      <label for="campaignname">Campaign Name:</label>
      </div>
      <div className='col-4'>
      <input type="text" class="form-control input disbleclass" id="campaignname" required name='campaignName' 
      value={campaign?.campaignName}
      disabled
 onChange={handleChange}
  placeholder="Campaign Name"/>
      </div>
      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Start Date:</label>
      </div>
      <div className='col-4'>
      {/* <input type="text" class="form-control" id="campaignname" placeholder="Campaign Name"/> */}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={value}
        name="startdt"
        format="dd-MMM-yyyy"
        // onChange={(newValue) => {
        //   setValue(newValue);
        // }}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}

<DatePicker
          className="adreservdate disbleclass"
          name='startDate'
          value={campaign?.startDate}
         disabled
          required
          selected={startDate}
             onChange={date => handleChangeDate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd MMM yyyy"
          />
      </div>
      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">End Date:</label>
      </div>
      <div className='col-4'>
      {/* <input type="text" class="form-control" id="campaignname" placeholder="Campaign Name"/> */}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      className='datepick'
        label="Date"
        value={value1}
        name="enddt"
        format="DD-MM-YYYY"
        // onChange={(newValue) => {
        //   setValue(newValue);
        // }}
        onChange={handleChangeDate1}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}
     <DatePicker
    required
          className="adreservdate disbleclass"
          name='endDate'
          disabled
          value={campaign?.endDate}

            selected={startDate1}
             onChange={date => handleChangeDate1(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate1}
            dateFormat="dd MMM yyyy"
          />
      </div>
      <div className='col-2'></div>
      </div>

      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Health Calculator Name:</label>
      </div>
      <div className='col-4'>
      
    <input type="text" class="form-control input disbleclass" id="campaignname" required name='calculatorName' 
      value={campaign?.calculatorName}
      disabled
 onChange={handleChange}
  placeholder="Campaign Name"/>      
    </div>
      <div className='col-2'></div>
      </div>
      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Offer:</label>
      </div>
      <div className='col-4'>
      
    <input type="text" class="form-control input " id="campaignname" required name='offer' 
      value={campaign?.offer}
 onChange={handleChange}
  placeholder=" Offer"/>      
    </div>
      <div className='col-2'></div>
      </div>
      <div className='form-group row'>
      <div className='col-2'></div>

      <div className='col-3 fontsize16 margintop5 textright'>
      <label for="campaignname">Offer On:</label>
      </div>
      <div className='col-4'>
      
    
  <select class="form-control input" id="exampleFormControlSelect1" size="auto"
      required
       onChange={handleChange} name='offeron' value={campaign?.service}
>
<option value="">Select</option>
      <option value="Consultation">Consultation</option>
      <option value="Nidanam package">Nidanam package</option>
      <option value='Panchakarmatherapies'>Panchakarmatherapies</option>
      <option value='Diet consultation'>Diet consultation</option>
      <option value='Yoga Cans Workshop'>Yoga Cans Workshop</option>
      <option value='Thyroid detox treatment'>Thyroid detox treatment</option>
      <option value='PCOS detox treatment'>PCOS detox treatment</option>
      <option value='Obesity treatment'>Obesity treatment</option>
      <option value='Diabetes treatment'>Diabetes treatment</option>
      <option value='Spine Disorder treatment'>Spine Disorder treatment</option>
      <option value='Ayurvedic Beauty treatment'>Ayurvedic Beauty treatment</option>
      <option value='Joint care treatments'>Joint care treatments</option>
      <option value='Janani- Infertility treatment'>Janani- Infertility treatment</option>



    </select>     
    </div>
      <div className='col-2'></div>
      </div>


<br/><br/>
      <div className='row margleft1'>
        <div className='col-4'></div>
        <div className='col-2'>
          <button className='cancelbtn' onClick={redirect}>Cancel</button>
        </div>
        <div className='col-2'>
        <button className='savebtn' >Save</button>
        <div className='row margintop1'></div>

        </div>
        <div className='col-3'></div>

      </div>
      <br/><br/>
      </form>

</div>
        
        
        <div>
           <div className={isdeletepopup?"popup-box12":"hide"}>
            <div className="box12">
                <div className="Deletpopdiv">
                    <span className="warnspn">Warning!</span>
                </div>
                <div className="warntxtdiv">
                    <span className="warntxt"> Do you Really want to delete these </span>
                    <div><span className="warntxt">Campaign ? </span></div>
                </div>
                <div className="warnbtn">
                    <button class=" cnceldeletpopbtn" onClick={redirectTo}> Cancel </button>
                    <button className="addeletepopbtn" onClick={() => confirmDelete1(campaign.id)}>Delete</button>
                </div>
            </div>
        </div>
           </div>  
     
     
    
         <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div> 
         </div>
          
          );
}
 
export default EditCoupon;