import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Switch, Route,Routes, Link, Redirect } from 'react-router-dom'
import Login from './Component/Login/Login';
import Dashboard from './Component/Dashboard';
import Forgot from './Component/Login/Forgot';
import PatientList from './Component/PatientList/PatientList';
import ViewPatientProfile from './Component/PatientList/ViewPatientProfile';
import Conclusion from './Component/PatientList/Conclusion';
import Report from './Component/Report/Report';
import Calculator from './Component/Calculator/Calculator';
import PcosHealthCalculator from './Component/Calculator/PcosHealthCalculator';
import Campaign from './Component/Campaign/Campaign';
import AddCampaign from './Component/Campaign/AddCampaign';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ViewCampaign from './Component/Campaign/ViewCampaign';
import AddCoupon from './Component/Campaign/AddCoupon';
import EditCampaign from './Component/Campaign/EditCampaign';
import ReportHc from './Component/Report/ReportHc';
import ReportCampaign from './Component/Report/ReportCampaign';
import ThyroidHealthCalculator from './Component/Calculator/ThyroidHealthCalculator';
import Footer from './Component/Footer';
import ViewCoupon from './Component/Campaign/ViewCoupon';
import EditCoupon from './Component/Campaign/EditCoupon';
import axios from "axios";
import { useState ,useEffect } from "react";

import { useHistory } from "react-router-dom";



function App() {

  //"proxy":"http://admin.tatchealthcalculator.com"



  //for session

  const [session, setSession] = useState(false);


  useEffect(() => {
    loadSession();
  }, []);

  const loadSession = async () => {
    debugger;
    const result = await axios.get("/checkSession");
    if(result.data.code==501){
      setSession(true)
    }
    else{
      setSession(false);
      //history.push("/PatientList");

    }
console.log("checksession",session)
  };


  
  return (
    <div className="App">
      <Router>
       
       <Switch>
        
        
        <Route exact path='/' component={Login}/>
        <Route  path='/Forgot' component={Forgot}/>

        
    
        <div>
        {/* <Route  path='/PatientList' component={PatientList}/> */}
      
        {session == false?  (
          <Route  path='/Login' component={Login}/>

        ):(
          <div>
                      <Route  path='/PatientList' component={PatientList}/>

          <Route  path='/Dashboard' component={Dashboard}/>
          <Route  path='/Conclusion' component={Conclusion}/>
      <Route  path='/Report' component={Report}/>
      <Route  path='/Calculator' component={Calculator}/>
      <Route  path='/PcosHealthCalculator/:id' component={PcosHealthCalculator}/>
      <Route  path='/Campaign' component={Campaign}/>
      <Route  path='/AddCampaign' component={AddCampaign}/>
      <Route  path='/ViewCampaign/:id' component={ViewCampaign}/>
      <Route  path='/ViewCoupon/:id' component={ViewCoupon}/>
      <Route  path='/EditCoupon/:id' component={EditCoupon}/>
      <Route  path='/ViewPatientProfile/:id' component={ViewPatientProfile}/>

      

      <Route  path='/AddCoupon/:id' component={AddCoupon}/>
      <Route  path='/EditCampaign/:id' component={EditCampaign}/>
      <Route  path='/ReportHc' component={ReportHc}/>
      <Route  path='/ReportCampaign' component={ReportCampaign}/>
      <Route  path='/ThyroidHealthCalculator/:id' component={ThyroidHealthCalculator}/>
      <Route  path='/Footer' component={Footer}/>
</div>
        )} 
      

        </div>
         


       
         
     </Switch>
     <ToastContainer autoClose={2500}  />

 
 
     </Router> 
    </div>
  );
}

export default App;
