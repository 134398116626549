import Sidebar from '../Sidebar';
import React, { useState, useEffect } from "react";
import {RiMenuLine} from 'react-icons/ri';

import axios from "axios";
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment, { duration } from 'moment'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer from "../Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../../images/thyroidlogo.jpg';

 
const AddCoupon=()=>{
  const [session ,setSession] = useState(false);

   const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
        history.push("/");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

//         console.log(session);

  useEffect(() => {
      gesessiondata();
    }, [session]);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());




  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
      const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
      const handleToggler =()=>{
        if(isExpanded){
          setIsExpanded(false);
          localStorage.setItem('sidebar-collapsed',true);
         return;
       }
       setIsExpanded(true);
        localStorage.removeItem('sidebar-collapsed');
     }

     let history = useHistory();


     const [campaign, setCampaign] = useState({
      id:"",
      campaignId:"",
      campaignName:"",
      startDate:"",
      endDate:"",
      calculatorName:"",
      couponCodeNo:"",
      offer:"",
      service:""
      
 
     })

     const handleChangeDate =(e)=>{
      setStartDate(e);
        setCampaign(preState => ({
          ...preState,
          "startDate": (moment(e).format("DD MMM yyyy")),

        }))
     }
     const handleChangeDate1 =(e)=>{
      setStartDate1(e);
      setCampaign(preState => ({
        ...preState,
        "endDate": (moment(e).format("DD MMM yyyy"))

      }))
   }
 
     const handleChange = (e) => {
      debugger;
      const { name, value, type, checked } = e.currentTarget;
  
  
      setCampaign(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
      }))
     }

     const {id} = useParams();

     useEffect(()=>{
      loadCampaign()
  },[])

const loadCampaign = async () =>{
  debugger;
  const res = await axios.get(`/campaign/getCampaignById?id=${id}`);
  debugger;
  console.log( "campainId", campaign.campaignId)
  setCampaign(res.data.data);

  setCampaign(preState => ({
    ...preState,
    "campaignId": res.data.data.id,
    "campaignName": res.data.data.name

  }))
}


     useEffect(() => {
      console.log("new data", campaign)
  }, [campaign])
    
  const onSave=()=>{
debugger;
      const response = axios.post('/campaign/addCouponCode', campaign)
    response.then(() => {
        //alert("data added")
       toast.success("Coupon  has been added Successfully!");
    
    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
     history.push("/Campaign");
    console.log("response", response?.data)
    
    }
      
   const redirect =()=>{

    history.push("/Campaign");
   }

   

  return (

<div className={ isExpanded ? " container" : "clpsMenu"}> 
          {/* <div className="container ">   */}
         <Sidebar isExpanded={isExpanded}></Sidebar>
         <div className={ isExpanded ? "sidebar-icon1 text_left" : "remanuLeftMargin"} >
         <RiMenuLine   className="rimwidthHeight" onClick={handleToggler}/>
         <span className="titlebar">Campaign</span>
        </div><br/><br/><br/>

        <div className={isExpanded?'subtitlebar':"subtitlebarclpse"}><span className='subtitlebartxt'>Campaign/ Coupon/ Add Coupon</span></div>

         <div className={isExpanded ? "usergrid" : "usergrid1"}>
         <form onSubmit={onSave}>

          <div className='row'>
          <div className='col-3'></div>
         <div className="clientbtn col-7">
            <div className="">
            <span className="adclptag">Add Coupon </span>
         </div>
        </div> 
        <div className='col-2'></div>
     </div> 

<br/><br/><br/>
<div className='row'>
  <div className='col-6'>
  <div className='form-group row'>
     <div className='col-1'></div>

      <div className='textright margintop5 fontsize16 col-4'>
      <label for="couponName">Coupon Code No:</label>
      </div>
      <div className='col-6'>
      <input type="text" class="form-control input texttransformcapital" id="couponName" name='couponCodeNo' required onChange={handleChange} placeholder="TATC-0123456"/>
      </div>
      <div className='col-1'></div>
      </div>

     <div className='form-group row'>
     <div className='col-1'></div>

      <div className='textright fontsize16 margintop5 col-4'>
      <label for="name">Campaign Name:</label>
      </div>
      <div className='col-6'>
      <input type="text" class="form-control input disbleclass" id="name"      
       value={campaign?.campaignName}
       required
       disabled
     name='campaignName' onChange={handleChange} placeholder="ABC" />
      </div>
      <div className='col-1'></div>
      </div>

      <div className='form-group row'>
      <div className='col-1'></div>

      <div className='col-4 fontsize16 margintop5 textright'>
      <label for="startDate">Start Date:</label>
      </div>
      <div className='col-6'>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Date"
        value={value}
        name="startdt"
        format="dd-MMM-yyyy"
        // onChange={(newValue) => {
        //   setValue(newValue);
        // }}
        onChange={handleChangeDate}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}
    <DatePicker
          className="adreservdate width340 disbleclass"
          name='startDate'
          disabled
          required
          selected={startDate}
             onChange={date => handleChangeDate(date)}

            selectsStart // tells this DatePicker that it is part of a range*
            value={campaign?.startDate}

            startDate={startDate}
            dateFormat="dd MMM yyyy"
          />
      </div>
      <div className='col-1'></div>
      </div>

      <div className='form-group row'>
      <div className='col-1'></div>

      <div className='col-4 fontsize16 margintop5 textright'>
      <label for="campaignname">End Date:</label>
      </div>
      <div className='col-6'>
      {/* <input type="text" class="form-control" id="campaignname" placeholder="Campaign Name"/> */}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
      className='datepick'
        label="Date"
        value={value1}
        name="enddt"
        format="DD-MM-YYYY"
        // onChange={(newValue) => {
        //   setValue(newValue);
        // }}
        onChange={handleChangeDate1}
        renderInput={(params) => <TextField {...params} fullWidth/>}
      />
    </LocalizationProvider> */}
    <DatePicker
    required
          className="adreservdate width340 disbleclass"
          name='endDate'
          value={campaign?.endDate}
  disabled
            selected={startDate1}
             onChange={date => handleChangeDate1(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate1}
            dateFormat="dd MMM yyyy"
          />

      </div>
      <div className='col-1'></div>
      </div>

      <div className='form-group row'>
      <div className='col-1'></div>

      <div className='col-4 fontsize16 margintop5 textright'>
      <label for="campaignname">Health Calculator Name:</label>
      </div>
      <div className='col-6'>
      
    <input type="text" class="form-control input disbleclass" id="name"      
       value={campaign?.calculatorName}
       required
       disabled
     name='calculatorName' onChange={handleChange} placeholder="HC"/>      
    </div>
      <div className='col-1'></div>
      </div>
      <div className='form-group row'>
      <div className='col-1'></div>

      <div className='col-4 fontsize16 margintop5 textright'>
      <label for="offer">Offer:</label>
      </div>
      <div className='col-6'>
      
    <input type="text" class="form-control input" id="offer"      
       value={campaign?.offer}
       required
     name='offer' onChange={handleChange} placeholder="Offer"/>      
    </div>
      <div className='col-1 textleft margintop5' style={{marginLeft:'-19px'}}><span className='fontsize16  '>%</span></div>
      </div>

      <div className='form-group row'>
      <div className='col-1'></div>

      <div className='col-4 fontsize16 margintop5 textright'>
      <label for="offer">Offer On:</label>
      </div>
      <div className='col-6'>
      
    
      <select class="form-control input" id="exampleFormControlSelect1" size="auto"
      required
       onChange={handleChange} name='service' value={campaign?.service}
>
      <option value="">Select</option>
      <option value="Consultation">Consultation</option>
      <option value="Nidanam package">Nidanam package</option>
      <option value='Panchakarmatherapies'>Panchakarmatherapies</option>
      <option value='Diet consultation'>Diet consultation</option>
      <option value='Yoga Cans Workshop'>Yoga Cans Workshop</option>
      <option value='Thyroid detox treatment'>Thyroid detox treatment</option>
      <option value='PCOS detox treatment'>PCOS detox treatment</option>
      <option value='Obesity treatment'>Obesity treatment</option>
      <option value='Diabetes treatment'>Diabetes treatment</option>
      <option value='Spine Disorder treatment'>Spine Disorder treatment</option>
      <option value='Ayurvedic Beauty treatment'>Ayurvedic Beauty treatment</option>
      <option value='Joint care treatments'>Joint care treatments</option>
      <option value='Janani- Infertility treatment'>Janani- Infertility treatment</option>



    </select>           
    </div>
      </div>

  </div>
  
  <div className='col-6'>
  <div className=" coupondiv">

<div className="row">
<div className="col-2">
<img src={logo} className="couponlogo" />
</div>
<div className="col-7 margintop5 marginlft textright">
    <span className="fontsize16  couponspn">The Ayurvedic T.H.Y.R.O.I.D. clinic</span>
</div>
</div>

<div className="row margtop8" style={{marginTop:-20}}>
<div className="col-2">
</div>
 <div  className="col-7 marginlft1 margtop7" style={{marginTop:-7}}>
    <span className=" couponspn2">TATC Coupon code</span>
</div> 
</div>
<div className="row margintop1">
<div className="col-3">
</div>
<div className="col-6  coponcodediv ">

    <span className=" couponspn3" >
      {campaign.couponCodeNo}
  </span>


</div>
</div>
<div className="row margintop3">
<div className="col-3">
</div>
<div className="col-5 marginlft1 ">
    <span className="fontsize16 couponspn">First Name, Last Name</span>
    <div className="margintop1 validtilspn">Valid till {campaign.endDate}</div> 

    
</div>
</div>

</div>
  </div>

  

</div>

<br/><br/><br/>
      
      <div className=' row'>
        <div className='col-4'></div>
        <div className='margleft1 col-2'>
          <button className='cancelbtn' onClick={redirect}>Cancel</button>
        </div>
        <div className='col-2'>
        <button className='savebtn'>Save</button>
        </div>
        <div className='col-3'></div>

      </div>
     
      <div className='row margintop'></div>
      <div className='row margintop1'></div>


  
  
  </form>
         </div>

         <div className={isExpanded ? "usermntmgtfooterexp" : "usermngmntfootercpls"}>  
   <Footer/> 
  </div>  
         </div>
          
          );
}
 
export default AddCoupon;