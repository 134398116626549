import './Sidebar.css';
import mothertext from '../images/thyroidlogo.jpg';
import mothertext1 from '../images/thyroidlogo.jpg';
import { Link, NavLink } from "react-router-dom";
import {RiMenuLine ,MdOutlineDashboard, RiTeamFill} from 'react-icons/md';
import {MdLogout} from 'react-icons/md';
import {MdOutlineListAlt} from 'react-icons/md';
import {RiFileCopy2Line} from 'react-icons/ri'
import {MdOutlineCalculate} from 'react-icons/md';
import {RiCouponLine} from "react-icons/ri"
import { useLocation } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import React,{ useState ,useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";



const SideBar =(props)=>{
  let history = useHistory();

  const logoutUser = async () => {
    debugger;
    axios.get('/logout')
      
    .then(result =>{
      if(result.data.code == 200)
      {
        history.push("/");
      }
      
      debugger;
      console.log(result.data.code);
    }).catch(error =>{
      console.log(error);
    })
  };
  const location = useLocation();
 
   //destructuring pathname from location
   const { pathname } = location;
 
   //Javascript split method to get the name of the path in array
   const splitLocation = pathname.split("/");
   let AddActive="";
   if(splitLocation[1] == 'Calculator' || splitLocation[1] == "PcosHealthCalculator"|| splitLocation[1] == "ThyroidHealthCalculator" ){
     AddActive="Active";
   }
   let AddActivePatientlist="";
   if(splitLocation[1] == 'PatientList'|| splitLocation[1] == "ViewPatientProfile" ){
    AddActivePatientlist="Active";
   }

   let AddActiveCampaign="";
   if(splitLocation[1] == 'Campaign'|| splitLocation[1] == "AddCampaign"|| splitLocation[1] == "ViewCampaign"|| splitLocation[1] == "EditCampaign"|| splitLocation[1] == "AddCoupon"|| splitLocation[1] == "ViewCoupon" || splitLocation[1] == "EditCoupon"){
    AddActiveCampaign="Active";
   }

   let AddActiveReport="";
   if(splitLocation[1] == 'Report'|| splitLocation[1] == "ReportHc"|| splitLocation[1] == "ReportCampaign" ){
    AddActiveReport="Active";
   }

   let AddActiveDashboad="";
   if(splitLocation[1] == 'Dashboard' ){
    AddActiveDashboad="Active";
   }
   
    const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');
    const [isExpanded ,setIsExpanded] = useState(sidebarCollapsed ? false :true);
    const [isOpen, setIsOpen] = useState(false);

        const toggleResermenu = () => {
           setIsOpen(!isOpen);
         }
   
 
   
 
   //assigning location variable
  

     return(
 
 <div> 
   {/* <Client isExpanded={isExpanded} className={AddActive === "Active" ? "":"hide"} /> */}
           <div className={props.isExpanded ? "Sidebar" : "Sidebar collapsed"} >
           <div className="sidebar-header">
               {/* <RiMenuLine className="sidebar-icon1 text_left" onClick={handleToggler}/> */}
               {/* <img className="sidebar-icon2" width={150} height={100} src={devalogo}/> */}
 
                <div className='flex'><img className={props.isExpanded ? "devaimg sidebar-logo":"sidebar-icon2" }src={props.isExpanded ? mothertext:mothertext1 }/><span className={props.isExpanded?'tatc':"tatcclpse"}>TATC</span></div>
                {/* <div className={props.isExpanded ? " tatctext":"tatctextclpse"} ></div> */}
             </div>
             <div className="sidebar-items">
       <div className= {AddActiveDashboad === "Active" ? "active item":"borderbottomnon item"}>
               <NavLink className= {AddActiveDashboad === "Active" ? "active sidemenu":"sidemenu"} activeClassName="active" exact to="/Dashboard">
                <MdOutlineDashboard size="1.6em" className="sidebar-icon icondash"/>
                 <span className="sidebar-text">Dashboard</span>
                 </NavLink>
               </div>

               <div className= {AddActivePatientlist === "Active" ? "active item":"borderbottomnon item"}>
               <NavLink className= {AddActivePatientlist === "Active" ? "active sidemenu":"sidemenu"} activeClassName="active" exact to="/PatientList">
               <MdOutlineListAlt size="1.6em" className="sidebar-icon icondash "/>
                 <span className="sidebar-text">Patient List</span>
                 </NavLink>
               </div>

               <div className= {AddActive === "Active" ? "active item":"borderbottomnon item"}>
               <NavLink className= {AddActive === "Active" ? "active sidemenu":"sidemenu"} activeClassName="active" exact to="/Calculator">
                <MdOutlineCalculate size="1.6em" className="sidebar-icon icondash"/>
                 <span className="sidebar-text">Calculators</span>
                 </NavLink>
               </div>

               <div className= {AddActiveCampaign === "Active" ? "active item":"borderbottomnon item"}>
               <NavLink className= {AddActiveCampaign === "Active" ? "active sidemenu":"sidemenu"} activeClassName="active" exact to="/Campaign">
               <RiCouponLine size="1.6em" className="sidebar-icon  marleftside"/>
                 <span className="sidebar-text">Campaigns</span>
                 </NavLink>
               </div>
               
               <div className= {AddActiveReport === "Active" ? "active item":" item"}>
               <NavLink className= {AddActiveReport === "Active" ? "active sidemenu":"sidemenu"} activeClassName="active" exact to="/Report">
                <RiFileCopy2Line size="1.6em" className="sidebar-icon  marleftside1"/>
                 <span className="sidebar-text"> Reports </span>
                 </NavLink>
               </div>
               
             {/* <div className="item logedout">
               <NavLink  className="sidemenu" activeClassName="active" exact to="/">
               <MdLogout size="1.6em" className="sidebar-icon icondashlogout" />
                 <span className="sidebar-text " >Logout</span>   
                 </NavLink>
                 
               </div> */}
               
               <div className='margintoplog'>
               <div className="item logedout logoutflex">
               <NavLink  className="sidemenu row " activeClassName="active" exact to="">
               <div className="sidebar-text usernmerol col-9" >Dr. Vikrant Patil </div>
                 <Tippy content="Logout">
               <div className='col-1'> 
                    <MdLogout className="sidebar-icon icondashlogout" size="1.5em" onClick={logoutUser}/></div>
                    </Tippy>
                   <div className='col-1'></div>
                 </NavLink>
                 
               </div>
               </div>
               
               
 
             </div>
 
 
 
             
         </div>
         </div>
     );
  }
  export default SideBar;